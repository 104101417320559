.form :global(.ant-form-item) {
  padding: 0 !important;
}

 /* .main :global(.ant-table-tbody > tr:nth-last-child(1) > td) {
  background-color: #dcf2ff;
} */

.main :global(.ant-table-tbody > tr:nth-last-child(1):hover > td) {
  background-color: #dcf2ff;
}

.total {
  background-color: #d9f2ff !important;
}


.noShowButton{
  width: 10px ;
  height: 32px;
  cursor: pointer;
  background-color: #ffffff;
}
.tabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab-active):before {
  background-color: #ffffff;
}

.tabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab-active):after {
  background-color: #ffffff;
}

.tabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab-btn) {
    color: #ffffff;
}

.tabs
  > :global(.ant-tabs-nav)
  :global(.ant-tabs-tab-active)
  :global(.ant-tabs-tab-btn) {
  color: primary-color;
  background-color: #ffffff;
}
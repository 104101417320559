
.fadeIn {
  opacity: 0;
  animation-name: fadeInAnimation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.carouselDiv{
  display: inline-block;
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
  width:100%;
}

.addDispose {
  position: absolute !important;
  bottom: 20px;
  left: 20px;
}
.DisposeItem {
  position: relative;
  border: 0.5px solid #e1e1e1;
  margin-bottom: 5px;
  cursor: default;
}

.DisposeItem:global(.ant-row) {
  margin: 0 !important;
}

.header {
  padding: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.headerTitle {
  display: flex;
  flex-direction: column;
}
.activeHeaderTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.outpatientName {
  color: #3788f7;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.container {
  width: 100%;
  /* height: calc(100% - 90px); */
  overflow-y: auto;
  font-size: 14px !important;
}

.modals :global(.ant-modal-body){
  height: 750px;
  overflow: auto;
}
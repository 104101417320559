.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 0px 20px;
  height: 100%;
  overflow-y: hidden;
}
.containers {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2px 20px 0px 20px;
  height: 102%;
  overflow-y: hidden;
}

.setting {
  position: absolute;
  top: 14px;
  right: 35px;
}

.main,
.monthMain {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: calc(100% - 10px);
  /* margin-top: 10px; */
  overflow: hidden;
  padding: 10px 15px 0 15px;
}

.mainNoPadding {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: calc(100% - 10px);
  overflow: hidden;
}

.dayMain {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: calc(100% - 0px);
  /* margin-top: 10px; */
  overflow: hidden;
  padding: 0px 15px 0 15px;
}

.monthMain :global(.ant-picker-calendar-header) {
  display: none;
}

.monthMain :global(.ant-picker-calendar) {
  height: 90%;
}
.detail {
  font-size: 16px;
  width: 90%;
}

.detailFlag {
  position: absolute;
  top: 20px;
  right: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.detailFlag .icon {
  width: 35px;
  height: 35px;
}
.detailFlag span {
  margin-top: 10px;
}

.detail :global(.ant-col) {
  margin-bottom: 20px;
}

.action {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 50px;
}

.limitPreson,
.moreTime {
  display: flex;
  align-items: center;
}

.limitPreson :global(.ant-input-group-wrapper) {
  width: 100px;
  margin-left: 10px;
}
.moreTime :global(.ant-input) {
  width: 100px;
  margin-left: 10px;
}

.weekTimeWrap {
  margin: 0 10px;
  width: 200px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #ccc;
}

.table {
  /* padding: 15px 10px; */
}

.container {
  position: relative;
  display: flex;
  height: 100%;
  overflow: auto;
  font-size: 14px;
}

.container .demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.container .demo-app-sidebar-section {
  padding: 2em;
}

.container .demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.container .fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
.container :global(.fc-scroller-liquid-absolute) {
  position: relative !important;
}
.container :global(.fc-button-primary) {
  background-color: #027aff !important;
}

.container :global(.fc-license-message) {
  display: none;
}

.container :global(.fc .fc-toolbar.fc-header-toolbar) {
  display: none !important ;
}

.container :global(.fc-event-main b) {
  display: block;
}
.container :global(.fc-scroller-harness-liquid .fc-scroller-liquid-absolute) {
  /* fc-scroller */
  /* overflow: hidden !important; */
}

.container :global(.fc-event-main) {
  overflow: hidden;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.container :global(.fc .fc-scrollgrid-liquid),
.container :global(.fc .fc-view-harness) {
  height: auto !important;
  padding-bottom: 10px;
}
.container :global(.fc-col-header) {
  width: 100% !important;
}
.container :global(.fc-timegrid-body) {
  overflow-x: auto !important;
  width: auto !important;
  overflow: hidden;
}

.container :global(.ant-table-wrapper) {
  padding: 0;
}

.container :global(.fc-theme-standard td) {
  border-color: #ccc !important;
}

.container :global(.fc-theme-standard th) {
  border-color: #ccc !important;
}

.container :global(.appointment_container__fAefS .fc .fc-scrollgrid-liquid) {
  border-color: #ccc !important;
}

.container
  :global(.ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-calendar-date) {
  border-top: 2px solid #ccc !important;
}

/* .container :global(.fc-timegrid-slot-label-frame) {
  text-align: left !important;
 } */

.container :global(.fc-event) {
  border: none;
}
.container :global(.fc-scroller-harness tr) {
  height: 30px;
}
.dayContainer
  :global(.fc-scrollgrid-section-body td):first-child
  :global(.fc-scroller-liquid-absolute) {
  overflow-y: hidden !important;
}

fc-scroller
  .dayContainer
  :global(.fc-scrollgrid-section-body .fc-scroller-liquid-absolute) {
  height: 682px;
  /* height: 100%; */
}

.dayContainer :global(.fc-event-time) {
  font-size: 18px !important;
  padding-left: 10px;
  /* height: 100%; */
}
.week :global(.toastui-calendar-template-time),
.week :global(.toastui-calendar-template-time span) {
  display: flex;
  flex-wrap: wrap;
}

/* .dayContainer :global(.fc-v-event .fc-event-main) {
  border: 1px solid #000;
} */

.modal {
  position: fixed;
  top: 403px;
  left: 826px;
  width: 350px;
  /* height: 250px; */
  background-color: #fff;
  box-shadow: 0px 0px 6px 1px #999;
  z-index: 9;
  padding: 15px;
}

.settingModal :global(.ant-tree-list) {
  height: 300px;
  overflow: auto;
}

.treeInp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.treeInp :global(.ant-input) {
  width: 80px;
  margin-left: 5px;
}

.monthIcon p {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.monthIcon .icon {
  width: 14px;
  height: 14px;
}

.setting :global(.ant-btn-background-ghost) {
  border: none;
}

.appointmentIcon {
  width: 18px;
  height: 18px;
}

.table :global(.ant-table-header) {
  min-width: 1262px !important;
  overflow: inherit !important;
}

.container :global(.fc-theme-standard td) {
  /* background: #fff !important; */
}

.modalbg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.dangersButton :global(.ant-btn) {
  background-color: red !important;
  border-radius: 4px !important;
  border: none !important;
  color: #fff !important;
}

.normalsButton :global(.ant-btn) {
  border-radius: 4px !important;
  border: none !important;
  color: #fff !important;
}

.dayMain :global(.fc-event-main > div) {
  height: 100% !important;
}

.content {
  width: 100%;
  height: 100%;
  font-size: 16px;
}
.card {
  width: 22%;
  height: 120px;
  float: left;
  margin: 20px 15px;
  background-color: #fff;
  font-size: 20px;
  text-align: center;
  line-height: 120px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.card:hover {
  color: #fff !important;
  background-color: #027aff;
  box-shadow: 0px 4px 12px 4px rgba(212, 234, 255, 0.8);
}

.tabs {
  height: 100%;
  overflow: auto;
  padding: 10px 20px;
  background: #fff;
  border-radius: 10px;
}

.tabs :global(.ant-tabs-content-holder) {
  height: 100%;
}

.tabs :global(.ant-tabs-content-holder .ant-tabs-content) {
  height: 100%;
}
.tabs :global(.ant-tabs-tabpane > div) {
  height: 100%;
}

.modal :global(.ant-modal-content) {
  height: 800px;
}

.options {
  background-color: #003bfe;
  border-radius: 2px;
}

.option {
  color: white;
  font-size: 0.875em;
  line-height: 2.1429em;
  text-align: center;
  cursor: pointer;
}

.option:hover {
  background-color: #0066ff;
}

.timeColor {
  display: inline-block;
  font-size: 14px;
  line-height: 1.875rem;
  background-color: #f5f7fb;
  padding: 0 10px;
  margin-right: 10px;
  color: #bbc3cc;
  margin-bottom: 5px;
}

.timeColors {
  display: inline-block;
  font-size: 14px;
  line-height: 1.875rem;
  background-color: #f5f7fb;
  padding: 0 5px;
  color: #bbc3cc;
  margin-bottom: 5px;
}

.time :global(.ant-form-item-label > label) {
  color: #646464;
  font-size: 12px;
}

.time
  :global(.ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before) {
  display: none;
}

.model :global(.ant-modal-body) {
  height: 580px;
  overflow: scroll;
}


.container{
  width: 100%;
  height: calc(100% - 90px);
  overflow-y: auto;
  font-size: 14px !important;
}
@value colors: "../../colors.css";
@value primary-color,
text-color-1,
text-color-2,
text-color-3,
input-border-color from colors;

/* :global {
    .ant-calendar-input-wrap{
      font-size: 14px;
      height: 45px;
      padding-left:12px;
      line-height: 45px;
    }
  } */

.head {
    background-color: #ffffff;
    margin-left: 19px;
    margin-right: 21px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 3px;
    padding-bottom: 5px;
    position: relative !important;
    height: 38px;
    box-shadow: 0px 2px 0px 0px #027aff;
}

.card {
    background-color: #ffffff;
    margin-left: 19px;
    margin-right: 21px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 3px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative !important;
}

.form {
    width: 85% !important;
}

.formcard {
    margin: 0 auto;
    float: left;
    width: 380px;
    height: 500px;
}

.add {
    position: absolute !important;
    right: 20px !important;
}

.formItem {
    display: inline-block !important;
    width: 380px !important;
    vertical-align: middle;
}

.suppliertable {
    height: calc(100% - 40px);
    width: 100%;
    margin-left: -2px;
    margin-top: -10px;
}

.suppliertable th {
    padding: 0 10px !important;
    height: 2.5rem;
    line-height: 0.3rem;
}

.content {
    height: calc(100% - 70px);
    margin: 10px 20px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
}
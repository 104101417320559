.container {
  background-color: #fff;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
  overflow-y: auto;
}

.backName {
  width: 270px;
  margin: 15px 0 22px 0;
  font-size: 16px;
  display: flex;
 justify-content: center;
}

.auth {
  padding: 10px;
  background-color: #eefaf4;
  border: 2px solid #0ebc6a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.authBtn {
  color: #fff;
  background-color: #0ebc6a;
  width: 130px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  cursor: pointer;
}

.example {
  font-size: 18px;
  font-weight: bold;
}

.exampleImg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.authCellModal {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.authCellModal span:first-child {
  width: 70%;
}

.authBtnModal {
  width: 110px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 5px;
  background-color: #408cff;
  cursor: pointer;
}

.authCellModal:nth-child(2) .authBtnModal {
  background-color: #0ebc6a;
}

.authCellModal:last-child .authBtnModal {
  background-color: #ffb145;
}

.QrCodeTitle {
  font-size: 14px;
  margin: 20px 0;
}

.QrCodeMain {
  width: 220px;
  height: 220px;
}

.qrMessageModal :global(.ant-modal-body) {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 320px;
}

/* 已授权内容 */
.aleadyAccredit {
  margin: 22px 0 0 20px;
  width: 240px;
  height: 280px;
  line-height: 20px;
  background-color: rgba(255, 255, 255, 1);
  text-align: center;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(238, 238, 238, 1);
  cursor: pointer;

}

.aleadyAccreditTitle {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
}

.aleadyAccreditTitleLeft {
  margin-left: 14px;
}

.aleadyAccreditTitleRight {
  width: 60px;
  height: 24px;
  border-radius: 4px 0px 0px 4px;
  background-color: #0ebc6a;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aleadyAccreditQrCode {
  width: 100px;
  height: 100px;
  margin: 0 70px 15px 70px;
}

.aleadyAccreditSubTitle {
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 15px;
}

.aleadyAccreditSubButtonTitle {
  width: 130px;
  height: 30px;
  border-radius: 4px;
  background-color: #408cff;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 55px;
  cursor: pointer;
}

.aleadyAccreditSubDelete {
  margin: 15px 16px 0 0;
  text-align: right;
}

.officialQrCode {
  width: 220px;
  height: 220px;
  background-color: #ffffff;
  margin: 0 70px 20px 70px;
}

.downLoad {
  height: 30px;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  font-family: Microsoft Yahei;
}

.viewport {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.banner {
  width: 280px;
  height: 300px;
  background-image: url(./assets/banner.png);
  background-size: cover;
}

.message {
  margin-bottom: 15px;
}

@value colors: "../../../colors.css";
@value primary-color, text-color-1, text-color-2, table-header-bg-color from colors;

.container {
  position: relative;
  overflow: hidden;
}
.container :global(.ant-divider-horizontal) {
  margin: 0 !important;
  border-top: solid 1px table-header-bg-color !important;
}

.warningDialog :global(.ant-modal-confirm-btns) {
  display: none !important;
}

.info {
  margin-top: 6px !important;
}
.info label {
  color: text-color-2;
  font-size: 1rem !important;
}

.patient label,
.doctor label {
  color: text-color-2;
  font-size: 0.875rem !important;
}

.info input,
.info textarea {
  color: text-color-1;
  font-size: 1rem !important;
  height: 2rem;
  min-height: 2rem !important;
}

.info :global(.ant-form-item-control-input-content) {
  margin-left: 10px;
}

.patient :global(.ant-input-affix-wrapper) {
  height: 2rem !important;
  margin-left: 10px !important;
}

.info > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}

.patient > :global(.ant-form-item),
.doctor > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}

.disease {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.875rem;
  background-color: table-header-bg-color;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.history :global(.ant-modal-body) {
  padding: 10px 20px !important;
}

.history :global(.ant-modal-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history :global(.ant-modal-body) {
  flex: 1;
  overflow: hidden;
}

.templates :global(.ant-collapse-header) {
  margin-top: 10px;
  color: text-color-1 !important;
  font-size: 1rem;
  height: 3rem !important;
  min-height: 3rem !important;
  background-color: table-header-bg-color;
  border-left: 2px solid primary-color;
  display: flex;
}

.templates :global(.ant-collapse-item) {
  border: none !important;
}

.info :global(.ant-checkbox-wrapper) {
  margin-left: 0 !important;
  margin-right: 8 !important;
}

.doctor :global(.ant-row) {
  flex-flow: row nowrap;
}

.doctor :global(.ant-form-item-control) {
  overflow: hidden;
}

.consultContainer {
  width: 25px;
  height: 75px;
  background-color: #027aff;
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #fff;
  z-index: 99999;
  overflow: hidden;
  /* border-radius: 5px 0 0 5px; */
  /* cursor: pointer; */
}
.drawer {
  height: 100%;
  position: fixed;
  top: 10px;
  transition: 0.75s;
}
.drawerContainer {
  width: 360px;
  height: 82%;
  background: #fff;
  position: relative;
  top: 100px;
}

.drawerLeft {
  position: absolute;
  left: -40px;
  height: 100%;
  width: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drawerBtn {
  writing-mode: vertical-rl;
  cursor: pointer;
  padding: 20px 0;
  padding-bottom: 10px;
  width: 25px;
  line-height: 25px;
  border-radius: 5px 0 0 5px;
  color: #fff;
  letter-spacing: 0.5em;
}
.drawerBtnIcon {
  position: relative;
  left: 1px;
}

.historyDialod {
  /* max-height: 400px; */
  /* overflow-y: auto; */
}

.modalTitle {
  margin-bottom: 10px;
  font-size: 16px;
  color: black;
}

.checkGroup :global(.ant-input-group-addon) {
  padding: 0 5px;
}

.checkGroup :global(.ant-input) {
  padding: 2px 5px;
}

.container :global(.ant-form-item-explain-error) {
  margin-left: 9px !important;
}

/*.container :global(.ant-select-selector) {*/
/*  height: 35px !important;*/
/*}*/

/*.container :global(.ant-input) {*/
/*  height: 35px !important;*/
/*}*/

.modal :global(.ant-modal-close-x) {
  display: none !important;
}

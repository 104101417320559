.container {
  height: 100%;
}

.main {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 20px;
  overflow-y: auto;
}

.left {
  width: 60%;
}

.upload {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.uploadButton {
  width: 88px;
  height: 32px;
  border: 1px solid #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  font-size: 14px;
  cursor: pointer;
}

.uploadText {
  font-size: 14px;
  color: #d1d0d1;
}

.right :global(.slick-dots li button) {
  display: none !important;
}

.imgWrap {
  position: relative;
  margin: 10px 10px 0px 10px;
  width: 100px;
  height: 100px;
  user-select: none;
}
.img {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.imgClose {
  position: absolute;
  right: -9px;
  top: -10px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 15px;
  color: #fff;
  background-color: #999999;
  border-radius: 50%;
  cursor: pointer;
}

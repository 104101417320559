.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  overflow-y: hidden;
}

.container :global(.ant-tabs),
.stacked {
  height: 100%;
}

.create {
  position: absolute !important;
  top: 14px;
  right: 30px;
}

.containerItem,
.qrCode {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 99%;
  margin-top: 10px;
  background-color: #fff;
  overflow-y: hidden;
  border-radius: 10px;
}

.stacked :global(.ant-row) {
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 42px;
}

.echarts {
  padding: 15px;
  width: 100%;
  height: 100%;
}

.btn {
  position: absolute !important;
  top: 15px !important;
  right: 35px !important;
}

.formItem {
  position: relative;
}

.span {
  width: 200px;
  position: absolute;
  top: 8px;
  right: -210px;
}

.Modal {
    width: 70% !important;
    height: 70% !important;
    padding: 0;
}

.Modal :global(.ant-modal-body) {
    padding: 0 !important;
}

.Modal :global(.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td),
.ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #9ED7FF !important;
}

.Modal .ant-table-fixed .ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #9ED7FF !important;
}
.OutpatientInfoTimeHeader {
  background-color: #edf3fc;
  padding: 10px;
  font-size: 16px;
}

.OutpatientInfoTime {
  border: 1px solid #e4e4e4;
  margin-bottom: 10px;
}

.OutpatientInfoTime :global(.ant-row) {
  margin: 0 !important;
}

.itemText {
  color: #2a7cf6;
  max-width: 30%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 10px;
  border-radius: 3px;
}

.timeline {
  padding: 0 10px;
  margin-left: 120px;
  border-left: 1px solid #e1e1e1;
  position: relative;
}

.timeline::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: #2a7cf6 2px solid;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.time {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-150%, -50%);
}

.timeCell {
  padding: 10px;
}

.cell {
  border: 1px solid #e9e9e9;
  display: flex;
}
.timeType {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  background-color: #f7f8f7;
}

.timeContent {
  padding: 10px;
  flex: 1;
}

.tooth {
  display: flex;
  align-items: center;
}


.main {
    height: calc(100% - 30px);
    /* margin-top: 10px; */
    padding: 20px;
    background: #FFFFFF;
    border: none;
    display: flex;
    flex-direction: column;
    border-radius: 10px !important;
    margin:15px
}

.titletext {
    height: 40px;
    background: #ffffff;
    box-shadow: 0px 1px 0px 0px #f3f7fd;
    width: 100%;
    color: #333333;
}

.form {
    width: 1240px !important;
}


.table {
    height: calc(100% - 30px);
    overflow: auto;
  }

.footer {
    margin: 12px 20px;
    height: calc(100% - 70% - 81px);
    margin: 12px 20px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
}

.lable:global(.ant-col .ant-form-item .ant-form-item-label>label) {
    font-size: 18px;
}

.Qingdou {
    height: calc(100% - 40px);
    width: 100%;
    margin-left: -2px;
    /* margin-top: -30px; */
}

.queryA {
    margin: 0 0 10px 0;
    display:flex
}

.queryA>a {
    margin-right: 10px;
    line-height: 32px;
    text-align: center;
    display: inline-block;
    width: 72px;
    height: 32px;
    border-radius: 2px;
}
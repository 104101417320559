.over {
  flex: 1;
  background: url("./assets//over.svg") no-repeat center;
  background-size: 70% 70%;
}

.cancel {
  flex: 1;
  background: url("./assets//cancel.svg") no-repeat center;
  background-size: 70% 70%;
}
.container {
  margin: 10px 20px !important;
}

.containerP {
  margin: 0px 0px !important;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.containerP :global(.ant-tabs-content){
  height: 100%;
}
.containerP :global(.ant-tabs-nav-wrap){
 padding-left: 30px;
}
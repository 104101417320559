@value colors: "../../colors.css";
@value primary-color,
tertiary-color,
text-color-2,
input-border-color from colors;
.entry:hover {
  box-shadow: 0px 5px 31px 1px rgba(0, 82, 217, 0.15);
}

.treatment:hover,
.message:hover {
  background-color: #f3f9fd;
}

.treatment:hover > div,
.message:hover > div {
  color: #027aff !important;
}

.overlay :global(.ant-popover-inner-content),
.overlay-hover :global(.ant-popover-inner-content) {
  padding: 0 !important;
}

.overlay-hover :global(.ant-popover-arrow) {
}

.menu:hover {
  color: #fff !important;
  background-color: #027aff !important;
}

.top:hover {
  background-color: #027aff !important;
  z-index: 99;
}

.top:hover.svg {
  fill: #ffffff !important;
  z-index: 999;
}

.top:hover .number {
  color: #ffffff !important;
  z-index: 9999;
}

.top:hover .photo {
  fill: #ffffff !important;
  width: 30px;
  height: 30px;
}

.top:hover .text {
  color: #ffffff !important;
  z-index: 9999;
}

.photo {
  width: 30px;
  height: 30px;
}

.menuSetting {
  position: absolute;
  top: 10px;
  right: 15px;
  color: #027aff;
  /* cursor: pointer; */
}

.menuSetting header,
.menuSetting :global(.ant-modal-title) {
  font-size: 20px;
  font-weight: 500;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.container :global(.ant-tabs-content-holder) {
  overflow: auto !important;
}

/* 商品管理 */
.delMedicalCode {
  position: relative;
  /* height: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 5px;
  border-radius: 2px;
}
.delMedicalCode :global(.ant-btn) {
  padding: 0 2px !important;
  flex: 1;
}

.delMedicalCode:hover {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
}

.Close {
  display: none;
  position: absolute;
  top: -15px;
  right: -10px;
  z-index: 99;
}
.delMedicalCode:hover .Close {
  display: block;
}

.hilistName {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.hilistName > div {
  display: flex;
  align-items: center;
  height: 20px;
}

.cancelButton :global(.ant-btn-primary) {
  border: 1px solid #999999 !important;
  color: #999999 !important;
}

.message :global(.ant-popover) {
  width: 400px !important;
}

.moreYBModal :global(.ant-modal-close-icon) {
  display: none !important;
}

.moreYBButtonMain {
  margin-top: 20px;
}

.moreYBButton {
  display: flex !important;
  justify-content: flex-end !important;
}

@value colors: "../../../colors.css";
@value text-color-1 from colors;
.form label {
  color: text-color-1;
  font-size: 1.125rem !important;
}

.form input,
.form textarea {
  color: text-color-1;
  font-size: 1.125rem !important;
}

.fst :global(.ant-form-item-label) {
  width: 6.75rem;
}

.form
  :global(.ant-select-single:not(.ant-select-customize-input) .ant-select-selector) {
  display: flex !important;
  height: 38px !important;
  align-items: center !important;
  font-size: 16px !important;
}

.fst > :global(.ant-row),
.snd > :global(.ant-row) {
  display: flex;
  align-items: center;
}

.snd :global(.ant-form-item-label) {
  width: 7.5rem;
}

.infoContainer {
  padding: 20px 40px;
}

.infoCell {
  margin-bottom: 15px;
}

.infoItem {
  width: 150px;
  text-align: right;
}

.patientModalTextArea :global(textarea.ant-input) {
  font-size: 16px !important;
}

.modal :global(.ant-input-disabled) {
  color: #333 !important;
}

.modal :global(.ant-picker-input > input) {
  color: #333 !important;
}

.modal
  :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector) {
  color: #333 !important;
}

@value colors: "../../../colors.css";
@value primary-color, text-color-1, text-color-2, table-header-bg-color from colors;

.container :global(.ant-divider-horizontal) {
  margin: 0 !important;
  border-top: solid 1px table-header-bg-color !important;
}

.container .chiefComplaintOverlay :global(.ant-divider-horizontal) {
  margin: 10px 0 !important;
}

.info {
  margin-top: 6px !important;
}
.info label {
  color: text-color-2;
  font-size: 1rem !important;
}

.patient label,
.doctor label {
  color: text-color-2;
  font-size: 0.875rem !important;
}

.info input,
.info textarea {
  color: text-color-1;
  font-size: 1rem !important;
  height: 2rem;
  min-height: 2rem !important;
}

.info :global(.ant-form-item-control-input-content) {
  margin-left: 10px;
}

.patient :global(.ant-input-affix-wrapper) {
  height: 2rem !important;
  margin-left: 10px !important;
}

.info > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}

.patient > :global(.ant-form-item),
.doctor > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}

.disease {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.875rem;
  background-color: table-header-bg-color;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.history :global(.ant-modal-body) {
  padding: 10px 20px !important;
}

.history :global(.ant-modal-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history :global(.ant-modal-body) {
  flex: 1;
  overflow: hidden;
}

.templates :global(.ant-collapse-header) {
  margin-top: 10px;
  color: text-color-1 !important;
  font-size: 1rem;
  height: 3rem !important;
  min-height: 3rem !important;
  background-color: table-header-bg-color;
  border-left: 2px solid primary-color;
  display: flex;
}

.templates :global(.ant-collapse-item) {
  border: none !important;
}

.info :global(.ant-checkbox-wrapper) {
  margin-left: 0 !important;
  margin-right: 8 !important;
}

.doctor :global(.ant-row) {
  flex-flow: row nowrap;
}

.doctor :global(.ant-form-item-control) {
  overflow: hidden;
}

.historyContainer {
  height: calc(100% - 115px);
  overflow: auto;
}

.historyContainer :global(.ant-collapse-content) {
  background-color: #fff;
}

.historyContainer :global(.ant-collapse-item) {
  background-color: #fff;
}

.historyContainer :global(.ant-collapse) {
  margin: 0 10px;
}

.historyContainer :global(.ant-collapse-header) {
  background-color: #fff;
}

.historyMessage {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.historyTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12;
}

.historyItem {
  display: flex;
  align-items: flex-start;
}

.historyItem div:last-child {
  flex: 1;
}

.historyNoWarp {
  width: 45px;
}

.historyWarp {
  width: 74px;
}

.historyWarpWidth{
  width: 86px;
}

.historyWarpWidthThreeText{
  width: 58px;
}

.rightContent{
  /* flex:1 !important; */
  overflow: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.rightContentText{
  white-space: pre-wrap;
  word-wrap: break-word;
}

.chiefComplaintContainer :global(.ant-form-item-control-input-content) {
  margin-left: 0;
}

.chiefComplaintOverlay {
  background-color: #fff;
  padding-top: 15px;
  font-size: 16px;
}

.chiefComplaintOverlay :global(.ant-tag) {
  font-size: 16px;
}

.title {
  color: #000;
  font-weight: 600;
}

.MedicalRecordModal {
  height: calc(100% - 57px);
  overflow: auto;
  display: flex;
  flex-wrap: nowrap;
}

.cell {
  width: 100px;
  color: #fff;
}

.cellItem,
.contentItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentItem {
  overflow: hidden;
  border-radius: 50%;
  justify-content: center;
  width: 90px;
  height: 90px;
  padding-top: 10px;
  cursor: pointer;
}

.contentDetail {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
  text-align: center;
}
.arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.arrow .arrowTop {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #9a9a9a;
}

.arrow .arrowMiddle {
  width: 2px;
  height: 20px;
  background-color: #9a9a9a;
}

.arrow .arrowBottom {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 14px solid #9a9a9a;
}

.checkGroup :global(.ant-input-group-addon) {
  padding: 0 5px;
}


.historyContainer :global(.ant-col) {
  /* margin-right: 10px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*.container :global(.ant-select-selector) {*/
/*  height: 35px !important;*/
/*}*/

/*.container :global(.ant-input) {*/
/*  height: 35px !important;*/
/*}*/

.container :global(.ant-form-item-control-input-content) {
  /* margin: 0 !important; */
}

.modal :global(.ant-modal-close-x) {
  display: none !important;
}


/* 目录分类设置模态框 */
.modalWrap :global(.ant-modal-header) {
}
.modalWrap :global(.ant-modal-title) {
}
.modalWrap :global(.ant-modal-close-x) {
  height: 42px !important;
  line-height: 42px !important;
  color: #fff;
}
.modalWrap :global(.ant-modal-body) {
  height: 500px;
  overflow-x: auto;
  padding:16px !important
}

/* 删除文字样式 */
.iptClose {
  margin-left: 10px;
  width: 35px;
  color: #879be3;
  cursor: pointer;
}

.MedicationAdministrationRecordContent{
  height: 530px;
  overflow: hidden;
}

.MedicationAdministrationRecordContent :global(.ant-table-body){
  overflow-x: hidden !important;
  max-height:410px !important;
  overflow-y: auto !important;
}


.form :global(.ant-form-item) {
  padding: 0 !important;
}

.total {
  background-color: #d9f2ff;
}

.fixed {
  display: table-cell;
  background-color: #d9f2ff !important;
}
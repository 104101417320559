@value colors: "../../colors.css";
@value table-header-bg-color from colors;

.tabs {
  padding: 0 20px !important;
  background-color: #fff;
}

.tabs :global(.ant-tabs-nav) {
  margin-top: 15px !important;
  background-color: table-header-bg-color !important;
}

.tabs :global(.ant-tabs-nav):before {
  display: none;
}

.tabs :global(.ant-tabs-tab) {
  padding: 10px 28px;
  margin: 0 !important;
}

.tabs :global(.ant-tabs-tab-active) {
  background-color: #ffffff;
}

.register :global(.ant-form-item) {
  margin-bottom: 0;
}

.register :global(.ant-form) {
  /* overflow: visible !important; */

}

.register :global(.ant-form-item-label-left) {
  text-align: right !important;
}

.register :global(.ant-dropdown) {
  left: -180px !important;
}

.disease {
  display: inline-block;
  font-size: 14px;
  line-height: 1.875rem;
  background-color: table-header-bg-color;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.diseasesList {
  position: absolute;
  top: 0;
  left: 0;
}

.disableModalContainer {
  position: relative;
  margin-top: 10px;
  overflow: auto;
  height: calc(100% - 10px);
  /* background-color: #fff; */
  border-radius: 10px;
}

.disableModal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  z-index: 999;
}

.membersInfoWrap {
  position: absolute;
  top: 38px;
  right: 0;
  padding-top: 20px;
  width: 200px;
  height: 200px;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 #ccc;
}

.title {
  height: 40px;
  display: flex;
  align-items: center;
}

.main {
  margin-bottom: 10px;
}

.text {
  color: #666666;
}

.register :global(.ant-radio-wrapper) {
  margin-right: 0 !important;
}

.overBox::-webkit-scrollbar {
  /* display: none; */
}

.overDisease {
  position: absolute !important;
  z-index: 999;
}

.contentFB :global(.ant-radio-group){
  white-space: nowrap !important;
}
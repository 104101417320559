.charge {
    flex: 1;
    border-radius: 10px !important;
}
/* 打印样式 */
.charges {
  flex: 1;
}
.charges th {
border: 1px solid #333 !important;
border-right: none !important;
}
.charges th:last-child {
border-right: 1px solid #333 !important;
}
.charges td {
border: 1px solid #333 !important;
border-top: none !important;
border-right: none !important;
}
.charges td:last-child {
border-right: 1px solid #333 !important;
}

.content {
    height: calc(100% - 30px);
    margin: 10px 0px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
}

.total {
  background-color: #d9f2ff;
}


.footingTitle {
  text-align: center;
}
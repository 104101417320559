.total {
    background-color: #d9f2ff;
}
.content {
    height: calc(100% - 70px);
    margin: 10px 20px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
}

.inventoryChange {
    height: calc(100% - 50px);
    width: 100%;
    margin-left: -2px;
}


.inventoryChange :global(.ant-table-summary .ant-table-cell-fix-left) {
  background-color: #d9f2ff !important;
}
@value colors: "../../colors.css";
@value text-color-2, table-header-bg-color from colors;

.table th,
.table td {
  color: text-color-2;
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap ;
}

.total {
  background-color: #d9f2ff !important;
}

.fixed {
  display: table-cell;
  background-color: #d9f2ff !important;
}

.label {
  width: 18px;
  height: 18px;
  padding: 3px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  height: 100%;
  overflow: hidden;
}

.table :global(.ant-table-body) {
  height: 39rem !important;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  overflow-y: hidden;
}
.main :global(.ant-tabs-content-holder) {
  margin-top: 10px;
}

.container {
  background-color: #fff;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
}

.uploadImg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.formModal {
  position: relative;
}
.formModal :global(.ant-input-disabled) {
  color: #333 !important;
}

.formModal :global(.ant-picker-input > input) {
  color: #333 !important;
}

.formModal
  :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector) {
  color: #333 !important;
}

.stopAction {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  /* width: 100vw;
  height: 100vh; */
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 50px;
}

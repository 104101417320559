.title,.subTitle{
  text-align: right;
}

.subTitle{
  background-color: #F1F7FF;

  padding: 5px 15px;
  
}
.subItem{
  /* padding: 5px 0; */
  border-bottom: 1px solid #FAFAFA;
  border-right: 1px solid #FAFAFA;
}
.subItem:first-child{
  border-top: 1px solid #FAFAFA;
}
.subItem .item:first-child {
  border-right: 1px solid #FAFAFA;
}
.item{
  text-align: left;
  padding-left: 15px;
}

.container{
  margin-top: 10px;
  width: 100%;
}

.upload {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.upload :global(.Icons_large__14hGG) {
  width: 4rem !important;
  height: 4rem !important;
  fill: #b8bcc5 !important;
}
.main :global(.ant-modal-title) {
  text-align: center;
}

.main{
  position:relative
}

.dashboard {
  height: 3.5rem;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px 0 -20px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
}

.dashboard span {
  margin-left: 16px;
}

.list {
  margin-top: 10px;
}

.label{
  margin-left: 3px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
}
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  overflow-y: hidden;
}

.main :global(.ant-tabs-content-holder) {
  margin-top: 10px;
}
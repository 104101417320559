.form {
    width: 85% !important;
    overflow: hidden;
}

.pagination {
    height: 50px;
    position: relative;
}

.puttable {
    height: 85%;
}

.puttable th {
    padding: 0 10px !important;
    height: 2.5rem;
    line-height: 0.3rem;
}
.supper {
  flex: 1;
  border-radius: 10px !important;
  overflow: auto;
}

.supper th,
.supper td {
  padding: 0 10px !important;
  height: 2.5rem;
  line-height: 2.3rem;
  font-size: 14px;
  white-space: nowrap;
}

.supper :global(.EditableList_wrapper__O5TS3) {
  line-height: 2rem !important;
}

.content {
  height: calc(100% - 70px);
  margin: 10px 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.multiple :global(.ant-select-selection-overflow) {
  max-width: 10rem;
  max-height: 26px;
  overflow: hidden;
}

.addtion :global(.ant-select-selection-overflow) {
  max-width: 10rem;
  max-height: 26px;
  overflow: hidden;
}

.addtion
  :glabal(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  max-height: 26px;
  overflow: hidden;
}

.Modal {
  width: 80% !important;
}

.select {
  width: 200px !important;
  min-width: 200px;
}


.Modal :global(.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td),
.ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #9ED7FF !important;
}

.Modal .ant-table-fixed .ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #9ED7FF !important;
}
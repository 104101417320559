.listItem {
  padding: 5px 10px;
  width: 100%;
  margin-bottom: 5px;
  border-bottom: 1px solid #f5f5f5;
}
.detail {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 55px;
}
.detailDown {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 55px;
}
.action {
  display: none;
}

.listItem:hover {
  background-color: #f5f5f5;
}

.listItem:hover .detail {
  /*display: none;*/
}
.listItem:hover .action {
  display: block;
}

.iconlabel {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 3px;
}

.cell,
.cellLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cellLeft :global(.ant-space-item) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell > div {
  width: 50%;
}

.name {
  font-size: 16px;
  font-weight: bold;
  border-radius: 3px;
}

.name,
.right > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.left {
  display: flex;
  align-items: center;
}

.right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.phone {
  display: flex;
  align-items: center;
}

.cellLeft :global(.ant-rate) {
  font-size: 12px !important;
}

.cellLeft :global(.ant-rate-star) {
  margin-right: 2px !important;
}

.cellLeft {
  justify-content: flex-start;
}
.custom-tooltip .ant-tooltip-inner {
  white-space: pre-wrap;
}
.icon {
  display: inline-block;
  width: 18px;
  height: 18px;
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  height: 55px;
}

.actionItem {
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.label {
  font-size: 20px;
  font-weight: bold;
}

.modal {
  position: fixed;
  top: 403px;
  left: 826px;
  width: 350px;
  /* height: 250px; */
  background-color: #fff;
  box-shadow: 0px 0px 6px 1px #999;
  z-index: 9;
  padding: 15px;
}

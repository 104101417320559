@value colors: "../../colors.css";
@value primary-color from colors;

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  overflow-y: hidden;
}

.container :global(.ant-modal-header) {
  border: none;
}

.infoIcon {
  font-style: 20px;
  color: #f98e35;
  font-weight: bold;
  margin-right: 10px;
}

.card,
.productCard {
  padding: 5px 20px 20px;
  background-color: #fff;
  border-radius: 8px;
  margin: 15px 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
}

.cardContainer {
  padding-left: 10px;
}

.productCardContainer {
  /* padding-left: 10px; */
  height: calc(100% - 208px);
  /* overflow: auto; */
  width: 100%;
  padding-top: 10px;
}

.cardItem {
  width: 31%;
  margin-left: 1.3%;
  position: relative;
  padding: 20px;
  background-color: #fff;
  height: 180px;
  margin-bottom: 1.3%;

  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.cardItem .header {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: start;
  /* margin-top: 15px; */
}
.cardItem .section {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 10px 0 25px;
  color: #999;
  height: 45px;
}
.seeDetail {
  color: #027aff;
  cursor: pointer;
}

.cardItem .price {
  color: primary-color;
  font-size: 22px;
}

.cardAction {
  position: absolute !important;
  top: 20px;
  right: 35px;
  z-index: 5;
}

.againModal :global(.ant-radio-button-wrapper) {
  height: 50px;
  line-height: 50px;
  /* padding: 0 25px; */
  margin-right: 15px;
  position: relative;
 
}

.againModal :global(.ant-radio-button-wrapper) > span {
  display: flex;
  justify-content: center;
 
}

.againModal :global(.ant-col) {
  line-height: 40px;
}


.discount {
  position: absolute;
  top: -9px;
  right: -8px;
  background: url(./images/back.png) no-repeat;
  background-size: 40px 19px;
  /* background-color: #F98E35; */
  color: #fff;
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shouldPay {
  color: #f98e35;
  font-size: 22px;
}

.qrCodeModal :global(.ant-modal-title) {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  overflow-y: hidden;
}

.wrap {
  background-color: rgb(255, 255, 255);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 10px);
    margin-top: 10px;
    overflow: hidden;
    padding: 10px 15px 0px;
}


.table {
  margin-top: 10px;

}

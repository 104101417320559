.head {
  background-color: #ffffff;
  margin-left: 19px;
  margin-right: 21px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 3px;
  padding-bottom: 5px;
  position: relative !important;
  height: 38px;
  box-shadow: 0px 2px 0px 0px #027aff;
}

.title {
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #f3f7fd;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  margin-bottom: 20px;
  padding-top: 10px;
}

.titletext {
  padding-left: 10px;
  border-left: 2px #0052d9 solid;
  height: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 12px;
  color: #333333;
}

.content {
  border-radius: 10px;
  padding: 10px 20px;
  height: 500px;
  margin: 12px 20px;
  background: #fff;
}

@value colors: "../../colors.css";
@value primary-color from colors;

.PriceModal {
  width: 100% !important;
}

.inventoryPrice {
  height: calc(100% - 40px);
  width: 100%;
  margin-left: -2px;
  margin-top: -10px;
}

.inventoryPrice :global(.ant-table-summary .ant-table-cell-fix-left) {
  background-color: #d9f2ff !important;
}
.total {
  background-color: #d9f2ff !important;
}

.content {
  height: calc(100% - 70px);
  margin: 10px 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.limit {
  font-size: 12px;
}
.limitItem {
  color: primary-color;
}

.table {
  flex: 1;
  overflow-y: auto;
}

.table th,
.table td {
  color: text-color-2;
  white-space: nowrap;
  line-height: 2.5rem;
  text-align: center;
  padding: 0 8px !important;
  font-size: 1rem !important;
}

.PriceModal :global(.ant-modal-body) {
  height: 700px !important;
  overflow-y: auto !important;
}

.form {
  width: 1200px !important;
}

.title {
  height: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #f3f7fd;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  margin-bottom: 20px;
}

.titletext {
  padding-left: 10px;
  border-left: 2px #0052d9 solid;
  height: 12px;
  font-size: 20px;
  line-height: 10px;
  font-weight: bold;
  color: #333333;
}
.fixed {
  display: table-cell;
  background-color: #d9f2ff !important;
}

.gray {
  background-color: #f4f4f4;
}

.storetable {
  height: 85%;
}

.storetable th {
  padding: 0 10px !important;
  height: 2.5rem;
  line-height: 0.3rem;
}

.select {
  width: 230px !important;
  min-width: 200px;
}

.content {
  height: calc(100% - 20px);
  margin: 10px 0px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.detailContent {
  height: calc(100% - 70px);
  margin: 10px 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.detailTable {
  height: calc(100% - 100px) !important;
  width: 100%;
}

.printRefTabel {
  width: 100%;
  /* overflow: hidden; */
  font-size: 12px !important;
}

.printRefTabel :global(.ant-table-cell) {
  font-size: 10px !important;
  font-weight: normal;
}
.detailContent :global(.ant-tabs-nav-wrap) {
  background: #f4f8fb;
  height: 40px;
}

.basicFactsMain :global(.ant-input-disabled) {
  color: #333 !important;
}
.basicFactsMain
  :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector) {
  color: #333 !important;
}

.detailContent :global(.ant-tabs-tab-active) {
  background: #fff;
}

.detailContent :global(.ant-tabs-tab) {
  padding: 5px 20px;
}

.detailContent :global(.ant-tabs-tab + .ant-tabs-tab) {
  margin: 0;
}

.detailContent :global(.ant-tabs-ink-bar) {
  position: absolute;
  background: #027aff;
  pointer-events: none;
  top: 0;
}

.detailContent :global(.ant-tabs) {
  flex: 1;
}

.detailContent :global(.ant-tabs-content-holder .ant-tabs-content) {
  height: 100%;
  margin-top: -15px;
}

.detailContent :global(.ant-tabs-content .ant-tabs-content-top) {
  display: flex;
  flex-direction: column;
}

.detailContent :global(.ant-tabs-tabpane-active) {
  display: flex;
  flex-direction: column;
}

.total {
  background-color: #d9f2ff;
}

.footingTitle {
  text-align: center;
}

.total {
  background-color: #d9f2ff;
}


.formQueryPopover :global(.ant-popover-inner-content){
  width:440px !important;
  padding:8px !important
}

.formQueryPopover :global(.ant-form-item){
  margin-bottom: 10px !important;
}
.container {
  padding: 10px 20px;
  height: 100%;
  overflow-y: hidden;
}

.containers {
  padding: 0;
  height: 100%;
  overflow-y: hidden;
}

.total {
  background-color: #d9f2ff !important;
}

.fixed {
  display: table-cell;
  background-color: #d9f2ff !important;
}

.tabs :global(.ant-tabs-nav) {
  margin: 0 !important;
}

.tabs :global(.ant-tabs-content-holder) {
  flex: 1 !important;
}

.containers :global(.ant-tabs) {
  height: 100% !important;
}

.tabs :global(.ant-table-container) {
  overflow: hidden;
}

.tabs :global(.ant-tabs-content) {
  height: 100% !important;
}

.tabs :global(.ant-table-body) {
  /* min-height: 482px !important; */
}

.table {
  overflow-x: scroll !important;
}


@value colors: "../../../colors.css";
@value primary-color, text-color-1, text-color-2, table-header-bg-color from colors;

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  width: 100%;
  /* overflow: hidden; */
}
.container :global(.ant-form-item) {
  margin-bottom: 0 !important;
}

.label {
  color: text-color-1;
  font-size: 1.125rem;
  line-height: 2.5rem;
  text-align: center;
  padding: 0px 20px;
  background-color: table-header-bg-color;
}

.tip :global(.ant-btn > .anticon + span) {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 450px !important;
  height: 18px !important;
}

.bar {
  margin-top: 10px;
  padding: 0 15px;
  padding-bottom: 10px;
  background-color: table-header-bg-color;
}

.button {
  height: 30px !important;
  margin-right: 10px;
  margin-top: 5.5px;
  line-height: 1.875rem;
  font-size: 1rem;
  padding: 0 10px !important;
}

.disposeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.filter {
  display: flex;
  flex-direction: column;
  padding: 27px 30px !important;
  align-items: center;
}

.bar {
  position: relative;
}

.bar > :global(.ant-btn-dangerous) {
  margin-right: 15px !important;
}

.tables :global(.ant-table-row) {
  height: 70px !important;
}

.table {
  flex: 1;
  overflow-y: auto;
  min-width: 1250px;
}

.tableKskd {
  /* flex: 1;
  overflow-y: auto; */
  min-width: 1380px;
  max-width: 1580px;
  /* margin-right: 20px; */
}

.tables {
  min-width: 1250px;
}

.toothTables :global(.ant-table-row) {
  height: 70px !important;
}

.tcmKskdTable {
  /* flex: 1; */
  /* overflow-y: auto; */
  min-width: 1100px !important;
}

.tcmTable {
  flex: 1;
  overflow-y: auto;
  /* max-width: 1100px !important; */
}

.tcmTable :global(.ant-table-thead > tr > th) {
  height: 31px !important;
  line-height: 31px !important;
}

.tcmTable :global(.ant-table-tbody > tr > td> div ){
line-height: 31px !important;
}


.medicalMessage:hover tr {
}

.tcmTable td {
  
}

.table th,
.table td,
.tables th,
.tables td {
  color: text-color-2;
  white-space: nowrap;
  text-align: center;
  padding: 0 8px !important;
  font-size: 0.875rem !important;
}
.tcmTable th,
.tcmTable td {
 color: text-color-2;
  white-space: nowrap;
  text-align: center;
  padding: 0 8px !important;
  font-size: 0.875rem !important;
  /* height: 38px !important; */
}

.tcmTable :global(.ant-table-tbody > tr > td){
  height: 0px !important;
  line-height: 0px !important;
}

.history :global(.ant-modal-body) {
  padding: 10px 20px !important;
}

.history :global(.ant-modal-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history :global(.ant-modal-body) {
  flex: 1;
  overflow: hidden;
}

.tip :global(.ant-input-number-handler-wrap) {
  display: none;
}

.tip input {
  text-align: center;
}

.drawer {
  height: 100%;
  position: absolute;
  top: 0;
}
.drawerContainer {
  width: 360px;
  height: 100%;
  background: #fff;
  position: relative;
}

.drawerLeft {
  position: absolute;
  left: -40px;
  height: 100%;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drawerBtn {
  writing-mode: vertical-rl;
  cursor: pointer;
  padding: 20px 0;
  padding-bottom: 10px;
  width: 40px;
  line-height: 40px;
  border-radius: 5px 0 0 5px;
  color: #fff;
}
.drawerBtnIcon {
  margin-top: 10px;
  margin-right: 5px;
  vertical-align: middle;
}

.curePlanListModal :global(.ant-modal-body) {
  position: relative;
  padding: 15px;
  height: 600px;
  overflow-y: auto;
}

.buttons {
  margin-right: 10px;
  margin-top: 10px;
  line-height: 1.875rem;
  font-size: 1rem;
  padding: 0 10px !important;
  position: relative;
}

.rpContent {
  min-width: 540px;
  height: 600px;
  overflow-y: auto;
  background-color: #fff;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 99;
  border-radius: 15px;
  box-shadow: rgba(88, 124, 253, 0.4) 5px 5px 35px 5px;
}

.rpContentTitle {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.rpContentMainContent {
  padding-left: 10px;
  /* display: flex;
  justify-content: space-between; */
}

.rpContentMain {
  height: 28px;
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 5px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.rpContentMainNormalWeight {
  font-size: 16px;
  font-weight: normal;
}

.minRecipeList {
  min-width: 1250px;
}

.maxRecipeList {
  max-width: 1210px;
}

.modal :global(.ant-modal-close-x) {
  display: none !important;
}


.styles{
  padding: 0 !important;
}

.popverContent :global(.ant-popover-content) {
  width: 400px !important;
  word-wrap: break-word !important;
}

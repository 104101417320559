.content {
  height: calc(100% - 20px);
  margin: 10px 20px;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.category{
  margin-bottom: 20px;
  padding-left: 13px;
}
.category header {
  font-weight: bold;
}

.categoryInfo{
padding: 15px 40px;
}

.categoryItem{
  padding: 3px 10px;
  border: 1px solid #999;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.detail{
  min-height: 100px;
  max-height: 500px;
  overflow-y: auto;
  margin:10px 15px;
}

.action{
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.action button:nth-child(2){
  margin-top: 5px;
}
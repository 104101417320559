.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  overflow-y: hidden;
}

.create {
  position: absolute !important;
  top: 14px;
  right: 30px;
}
.content {
  height: calc(100% - 20px);
  margin: 10px 0;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}
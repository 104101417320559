.container {
  height: 100%;
  border-radius: 8px;
  margin: 0 20px;
}

.tabs:global(.ant-tabs) {
  height: 96%;
  background-color: #fff;
  border-radius: 8px;
}

.tabs {
  padding: 0 20px !important;
}

.tabs :global(.ant-tabs-nav):before {
  display: none;
}

.tabs :global(.ant-tabs-tab) {
  margin-right: 6px !important;
}

.tabs :global(.ant-tabs-nav) {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
}

.tabs :global(.ant-tabs-content) {
  height: 100% !important
}
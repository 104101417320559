@value colors: "../../colors.css";
@value primary-color, text-color-1, table-header-bg-color from colors;

.toolRow {
    width : 100%;
    border:1px solid #fff;
    display: flex;
    alignItems: center;
    justifyContent: flex-start;
    height: 48px;
}

.hydTool {
    width: 25px;
    height: 25px;
    margin-top: 16px;
    margin-right: 5px;
    cursor: pointer;
}

.ulContainer {
    width : 100%;
    height: 96px;
    text-align: center;;
    border:1px solid #fff;
}

.dragItem:hover {
    color: #409eff;
    cursor: move;
}

.toolRow :global(.ant-select-selector) {
    height: 26px !important;
}

.toolRow :global(.ant-select) {
    height: 26px !important;
}

.toolRow :global(.ant-select-selection-item) {
    height: 26px !important;
    line-height: 26px !important;
}

.templates :global(.ant-collapse-header) {
    margin-bottom: 10px;
    color: text-color-1 !important;
    font-size: 1.125rem;
    height: 1rem !important;
    min-height: 3rem !important;
    background-color: table-header-bg-color;
    border-left: 2px solid primary-color;
    display: flex;
    flex: 1;
    position: relative;
}

.templates :global(.ant-collapse-item) {
    border:1px solid #fff!important;
}

.secondLevel :global(.ant-collapse-header) {
    border-left: #FFFFFF!important;
    background-color: #FFFFFF!important;
    margin-bottom : 0px!important;
    padding : 0px 0px!important;
    height : 1rem!important;
    min-height: 2rem !important;
}

.secondLevel :global(.ant-collapse-item) {
    background-color: #FFFFFF!important;
    border:1px solid #fff!important;
}

.secondLevel :global(.ant-collapse-content-box) {
    padding-top : 0px!important;
    padding-bottom: 10px!important;
    background-color: #FFFFFF!important;
}

.threeLevel :global(.ant-collapse-content-box) {
    padding-top : 0px!important;
    padding-bottom: 10px!important;
    background-color: #FFFFFF!important;
}


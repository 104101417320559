.bar {
  height: 2.5rem;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.bar > div:first-child {
  flex: 1;
}

.bar > div:last-child {
  flex: 0;
}

.button {
  border-radius: 2px;
  height: 2rem;
}

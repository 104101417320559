.total {
  background-color: #d9f2ff !important;
}

.fixed {
  display: table-cell;
  background-color: #d9f2ff !important;
}

.label {
  width: 18px;
  height: 18px;
  padding: 3px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  height: 100%;
  overflow: hidden;
}

.table :global(.ant-table-body) {
  height: 39rem !important;
}

.traceCodeCol {
  margin-top:5px;
}

.traceCodeInput :global(.ant-input
){
  font-size: 14px !important;
  font-weight: bold !important;
}

.traceNameCol{
  color: #547ef3;
  font-size: 15px;
  font-weight: bold;
}
.traceCodeModal :global(.ant-modal-body) {
  height:600px;
  padding:10px;
  overflow: auto;
}
.tips{
  border-style:dashed;
  border-color:#a5a5a5;
  border-width: 2px;
  padding:5px;
  margin-bottom: 10px;
  color:#5b5a5a;
}
.errorMsg{
  color: #fa1e1e;
  font-size: 15px;
  font-weight: bold;
}
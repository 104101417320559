@value colors: "../../colors.css";
@value primary-color, text-color-1, text-color-2, text-color-3, input-border-color from colors;

.tabs > :global(.ant-tabs-nav) {
  height: 2.5rem;
  margin-bottom: 0px !important;
  background-color: #ffffff;
  border-radius: 2px;
  border-bottom: solid 2px primary-color;
}

.tabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab) {
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-align: center;
  border: none !important;
  background-color: #ffffff !important;
  padding: 8px 24px 8px 16px !important;
}

.tabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab-active):before {
  content: "";
  position: absolute;
  right: 6px;
  top: 0;
  width: 50px;
  height: 100%;
  background-color: primary-color !important;
  overflow: hidden;
  transform: skewX(15deg);
}

.tabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab-active):after {
  content: "";
  position: absolute;
  left: 0;
  right: 20px;
  top: 0;
  bottom: 0;
  background-color: primary-color !important;
}

.tabs
  > :global(.ant-tabs-nav)
  :global(.ant-tabs-tab-active)
  > :global(.ant-tabs-tab-btn) {
  z-index: 10;
  color: #ffffff !important;
}

.tabs :global(.ant-tabs-tab-btn) {
  transition: none !important;
}

.tabs {
  height: 100%;
  display: flex !important;
}

.tabs :global(.ant-tabs-content-holder) {
  flex: 1 !important;
  overflow-y: hidden;
}

.tabs :global(.ant-tabs-content) {
  height: 100%;
}

.small {
    width: 1.125rem;
    height: 1.125rem;
}

.normal {
    width: 1.5rem;
    height: 1.5rem;
}

.large {
    width: 2rem;
    height: 2rem;
}
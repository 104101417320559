@value colors: "../../../colors.css";
@value primary-color, text-color-1, text-color-2, table-header-bg-color from colors;

.container :global(.ant-divider-horizontal) {
  margin: 0 !important;
  border-top: solid 1px table-header-bg-color !important;
}

.info {
  margin-top: 6px !important;
}
.info label {
  color: text-color-2;
  font-size: 1rem !important;
}

.patient label,
.doctor label {
  color: text-color-2;
  font-size: 0.875rem !important;
}

.info input,
.info textarea {
  color: text-color-1;
  font-size: 1rem !important;
  height: 2rem;
  min-height: 2rem !important;
}

.info :global(.ant-form-item-control-input-content) {
  margin-left: 10px;
}

.patient :global(.ant-input-affix-wrapper) {
  height: 2rem !important;
  margin-left: 10px !important;
}

.info > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}

.patient > :global(.ant-form-item),
.doctor > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}

.disease {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.875rem;
  background-color: table-header-bg-color;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.history :global(.ant-modal-body) {
  padding: 10px 20px !important;
}

.history :global(.ant-modal-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history :global(.ant-modal-body) {
  flex: 1;
  overflow: hidden;
}

.templates :global(.ant-collapse-header) {
  margin-top: 10px;
  color: text-color-1 !important;
  font-size: 1rem;
  height: 3rem !important;
  min-height: 3rem !important;
  background-color: table-header-bg-color;
  border-left: 2px solid primary-color;
  display: flex;
}

.templates :global(.ant-collapse-item) {
  border: none !important;
}

.info :global(.ant-checkbox-wrapper) {
  margin-left: 0 !important;
  margin-right: 8 !important;
}

.doctor :global(.ant-row) {
  flex-flow: row nowrap;
}

.doctor :global(.ant-form-item-control) {
  overflow: hidden;
}

.opBar {
  position: absolute;
  right: 10px;
}

.opBar :global(.ant-space-item) {
  display: flex;
}

.opBar :global(.ant-collapse-header-text) {
  flex: 1;
}

.PrintFrame {
  width: 830px;
  padding: 40px;
}

/* 历史病历样式 */
.itemWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 10px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  border: 1px solid #eeeeee;
}

.imgName {
  /* display: inline-block; */
   display: flex;
  color: #027aff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  text-align: center;
}

@value colors: '../../colors.css';
@value text-color-3 from colors;

.notFound {
  width: 100%;
  height: 100%;
  color: text-color-3;
  font-size: 5rem;
  text-align: center;
  background-color: #fff;
  user-select: false;
  box-sizing: border-box;
}

.notFound:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

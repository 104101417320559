.recardDrug {
    flex: 1;
    border-radius: 10px !important;
}

.content {
    height: calc(100% - 70px);
    margin: 10px 20px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px !important;
}

.select {
    width: 200px !important;
    min-width: 200px;
}


.total {
  background-color: #d9f2ff !important;
}

.content :global(.ant-checkbox-wrapper) {
    display: flex !important;
    align-items: baseline !important;
}


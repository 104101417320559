.PatientLayout{
  margin: 10px;
  height: calc(100% - 20px);
  display: flex;
}
.PatientLayout>div{
  background-color: #fff;
  padding-top: 10px;
  border-radius: 8px;
}

.PatientLeftAction{
  position: relative;
}

.tabsContainer{
  height: calc(100% - 50px);
  overflow: hidden;
}
.tabsContainer :global(.ant-tabs-content){
  height: 100%;
}
.tabsContainer :global(.ant-tabs-tabpane){
  padding: 0 10px;
}

.todayTabs{
  height: calc(100% - 90px) !important;
  overflow: auto;
}
.todayTabs :global(.ant-tabs-tabpane){
  height: auto;
  padding: 0;
}
.todayTabs :global(.ant-tabs-content-holder){
 overflow: auto;
}

.todayTabs :global(.ant-tabs-tabpane){
  padding: 0 10px;
}
.expansionAction{
  position: absolute;
  top:18px;
  right: 16px;
  font-size: 18px;
  color: #999;
  z-index: 999;
}

.tabsContainer :global(.ant-tabs-nav-wrap):first-child{
  padding-left: 20px;
}

.PatientLayout :global(.ant-tabs-nav){
  margin-bottom: 8px !important;
}


.PatientRight{
  flex: 1;
  margin-left: 10px;
  padding: 10px 10px 0 10px;
  overflow: hidden;
  position: relative;
}
.todayHeader{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.todayTime{
  display: flex;
  align-items: center;
  border: 1px solid #e1e1e1;
  margin-right: 10px;
}

.todayTime :global(.ant-picker-suffix) {
  display: none;
}

.listItem{
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.listItemHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.listItemHeader div:nth-child(1){
  font-weight: bold;
}

.listItemHeader div:nth-child(2){
  color: #027AFF;
}
.listItemHeader div:nth-child(2) span{
  color: #999;
  margin-left: 5px;
}
.leftFooter{
  display: flex;
  justify-content: center;
}

.allItem{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.allEdit{
  display: flex;
  align-items: center;
  justify-content: center;
}

.allAdd{
  display: flex;
  justify-content: flex-end;
}

.noData{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
}

.noPageData{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.highlight {
  background-color: #eef8ff;
}

.total {
  height: 40px;
  background-color: #d9f2ff;
}

.table-print table{border-right:1px solid #F00;border-bottom:1px solid #F00}
.table-print table td{border-left:1px solid #F00;border-top:1px solid #F00}

.table th {
  color: #666 !important;
  font-size: 1rem;
  height: 3.75rem;
  line-height: 3.75rem;
  padding: 0 10px !important;
  white-space: nowrap;
}

.table th:first-child {
  padding: 0 !important;
}

.table td {
  color: #666 !important;
  font-size: 1rem;
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0 10px !important;
  white-space: nowrap;
}
/* 打印样式 */
.tables th {
  color: #666 !important;
  border: 1px solid #333 !important;
  border-right: none !important;
  font-size: 1rem;
  height: 3.75rem;
  line-height: 3.75rem;
  padding: 0 10px !important;
}
.tables th:last-child {
  border-right: 1px solid #333 !important;
}

.tables th:first-child {
  padding: 0 !important;
}

.tables td {
  color: #666 !important;
  border: 1px solid #333 !important;
  border-top: none !important;
  border-right: none !important;
  font-size: 1rem;
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0 10px !important;
}
.tables td:last-child {
  border-right: 1px solid #333 !important;
}
.total > td {
  color: #027aff !important;
}

.total > td:first-child {
  font-weight: bold !important;
}

@media print {
  .table{
    border:1px solid black;
    border-left:0px solid black;
  }
  .table th{
    border:1px solid black;
  }
  .table td {
    border:1px solid black;
    font-size: 0.875rem;
  }
}

.palneItem {
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 6px;
}
.palneItem span {
  font-size: 30px;
  height: 42px;
  line-height: 42px;
}

.total :global(.ant-table-cell-fix-left) {
  background-color: #d9f2ff !important;
}

.total :global(.ant-table-cell-fix-right) {
  background-color: #d9f2ff !important;
}

/*.highlight :global(.ant-table-cell-fix-left) {*/
/*  background-color: #eef8ff !important;*/
/*}*/

.highlight :global(.ant-table-cell-fix-right) {
  background-color: #eef8ff !important;
}

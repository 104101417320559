@value colors: "../../colors.css";
@value primary-color from colors;

.where0:before {
  content: "";
  position: absolute;
  right: 6px;
  top: 0;
  width: 20px;
  height: 100%;
  background-color: primary-color !important;
  overflow: hidden;
  transform: skewX(15deg);
  z-index: 0;
}

.where0:after {
  content: "";
  position: absolute;
  left: 0;
  right: 15px;
  top: 0;
  bottom: 0;
  background-color: primary-color !important;
  z-index: 0;
}

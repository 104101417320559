.header {
  padding: 10px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}

.headerTitle {
  display: flex;
  flex-direction: column;
}

.content {
  padding: 10px 0 10px 10px;
  height: calc(100% - 34px);
  /* background-color: red; */
}

.backs {
  margin-bottom: 12px;
  border: 1px solid #eee;
}



.backsImgBack {
  width: 19%;
  padding-top: 19%;
  border: 1px solid #eee;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.backsImg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.backsImg  :global(.ant-image-img){
  width: 100% !important;
  height: 100% !important;
}

.backsImgMain {
  width: 100%;
  display: flex;
  flex-basis: 19%;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: 0px 10px 14px 10px;
}

.backsImgMain > .backsImg:last-child {
  margin-right: 0px !important;
}

@value colors: "../../../colors.css";
@value primary-color, text-color-1, text-color-2, table-header-bg-color from colors;

.container :global(.ant-divider-horizontal) {
  margin: 0 !important;
  border-top: solid 1px table-header-bg-color !important;
}
.container :global(.ant-menu-item) {
  background: #fefefe;
  color: #000;
}
.container :global(.ant-menu-item-selected),
.container :global(.ant-menu-item-active) {
  background: rgb(226, 242, 255) !important;
  color: #000 !important;
}

.container :global(.ant-menu-inline .ant-menu-item::after) {
  right: 99% !important;
  border-right-color: #027aff !important;
}
.info {
  margin-top: 6px !important;
}
.info label {
  color: text-color-2;
  font-size: 1rem !important;
}

.patient label,
.doctor label {
  color: text-color-2;
  font-size: 0.875rem !important;
}

.info input,
.info textarea {
  color: text-color-1;
  font-size: 1rem !important;
  height: 2rem;
  min-height: 2rem !important;
}

.info :global(.ant-form-item-control-input-content) {
  margin-left: 10px;
}

.patient :global(.ant-input-affix-wrapper) {
  height: 2rem !important;
  margin-left: 10px !important;
}

.info > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}

.patient > :global(.ant-form-item),
.doctor > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}

.disease {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.875rem;
  background-color: table-header-bg-color;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.history :global(.ant-modal-body) {
  padding: 10px 20px !important;
}

.history :global(.ant-modal-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history :global(.ant-modal-body) {
  flex: 1;
  overflow: hidden;
}

.templates :global(.ant-collapse-header) {
  margin-top: 10px;
  color: text-color-1 !important;
  font-size: 1rem;
  height: 3rem !important;
  min-height: 3rem !important;
  background-color: table-header-bg-color;
  border-left: 2px solid primary-color;
  display: flex;
}

.templates :global(.ant-collapse-item) {
  border: none !important;
}

.info :global(.ant-checkbox-wrapper) {
  margin-left: 0 !important;
  margin-right: 8 !important;
}

.doctor :global(.ant-row) {
  flex-flow: row nowrap;
}

.doctor :global(.ant-form-item-control) {
  overflow: hidden;
}

.opBar {
  position: absolute;
  right: 10px;
}

.opBar :global(.ant-space-item) {
  display: flex;
}

.opBar :global(.ant-collapse-header-text) {
  flex: 1;
}

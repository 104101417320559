.Detail {
  height: 90%;
  padding: 20px;
  padding-bottom: 40px;
  margin: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  overflow-y: scroll;
}

.title {
  height: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #f3f7fd;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  margin-bottom: 20px;
}

.titletext {
  padding-left: 10px;
  border-left: 2px #0052d9 solid;
  height: 12px;
  font-size: 20px;
  line-height: 10px;
  font-weight: bold;
  color: #333333;
}

.Detail :global(.ant-input-disabled) {
  color: #333 !important;
}

.Detail :global(.ant-picker-input > input) {
  color: #333 !important;
}

.Detail
  :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector) {
  color: #333 !important;
}


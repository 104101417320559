@value colors: "../../colors.css";
@value primary-color, tertiary-color, text-color-2, input-border-color from colors;

.container {
  padding: 15px 30px;
  height: 100%;
  overflow-y: auto;
  margin: 10px 20px;
  border-radius: 4px;
  background-color: #ffffff;
}

.title {
  color: text-color-1;
  font-size: 1.25rem;
  line-height: 2.5rem;
  font-weight: bold;
}

.class1 {
  margin-top: 20px;
  padding: 10px 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: tertiary-color;
}

.class2 {
  margin-top: 20px;
}

.name {
  color: text-color-1;
  font-size: 1.125rem;
  line-height: 3rem;
  background-color: tertiary-color;
  padding: 0 20px;
}

.sub {
  padding: 10px 20px;
  border-left: solid 1px input-border-color;
  border-right: solid 1px input-border-color;
  border-bottom: solid 1px input-border-color;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}
.container :global(.ant-tabs-content-holder) {
  overflow: hidden !important;
}

.create {
  position: absolute !important;
  top: 14px;
  right: 30px;
}

.supper {
    flex: 1;
    border-radius: 10px !important;
}

.content {
    height: calc(100% - 70px);
    margin: 10px 20px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
}

.title {
    height: 30px;
    background: #ffffff;
    box-shadow: 0px 1px 0px 0px #f3f7fd;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    margin-bottom: 20px;
    padding-top: 10px;
}

.titletext {
    padding-left: 10px;
    border-left: 2px #0052d9 solid;
    height: 10px;
    font-size: 18px;
    font-weight: bold;
    line-height: 12px;
    color: #333333;
}

.select {
    width: 200px !important;
    min-width: 200px;
}
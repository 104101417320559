@value colors: "../../colors.css";
@value table-header-bg-color from colors;

.tabs {
  padding: 0 20px !important;
}

.tabs:global(.ant-tabs ) {
 height:100%
}
.tabs {
  padding: 0 20px !important;
}

.tabs :global(.ant-tabs-nav) {
  margin-top: 15px !important;
  background-color: table-header-bg-color !important;
}

.tabs :global(.ant-tabs-nav):before {
  display: none;
}

.tabs :global(.ant-tabs-tab) {
  padding: 10px 28px;
  margin: 0 !important;
}

.tabs :global(.ant-tabs-tab-active) {
    background-color: #ffffff;
}

.root {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.commoditytable {
  height: calc(100% - 110px);
}

.commoditytable th {
  padding: 0 10px !important;
  height: 2.5rem;
  line-height: 0.3rem;
}

.commoditytable td {
  color: text-color-1;
  font-size: 14px;
}

.head {
  background-color: #ffffff;
  margin-left: 19px;
  margin-right: 21px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 3px;
  padding-bottom: 5px;
  position: relative !important;
  height: 38px;
  box-shadow: 0px 2px 0px 0px #027aff;
}

.add {
  width: 146px;
  height: 32px;
  float: left;
  margin-top: 10px;
  position: relative;
}
.adds {
  width: 110px;
  height: 32px;
  float: left;
  margin-top: 10px;
  position: relative;
}

.add:hover .addmenu {
  display: block;
}

.add:active .addmenu {
  display: block;
}

.addmenu {
  display: none;
  position: absolute;
  z-index: 99;
  top: 32px;
  left: 0;
  width: 146px;
  list-style: none;
  margin: 0 auto;
  padding: 0;
}

.addmenu > li {
  border-radius: 2px;
  width: 146px;
  height: 36px;
  line-height: 36px;
  color: #666666;
  background-color: #ffffff;
}

.addmenu > li:hover {
  background-color: #f3f9fd;
}

.addmenu > li:active {
  background-color: #027aff;
  color: #ffffff;
}

.content {
  padding: 20px 20px;
  height: 100%;
  margin: 12px 20px;
  border-radius: 10px;
  background: #fff;
}

.scroll {
  margin: 0;
  padding: 0;
  height: 85%;
  overflow: auto;
}

.icon {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.form {
  width: 1200px !important;
}

.title {
  height: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #f3f7fd;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  margin-bottom: 20px;
}

.titletext {
  padding-left: 10px;
  border-left: 2px #0052d9 solid;
  height: 12px;
  font-size: 20px;
  line-height: 10px;
  font-weight: bold;
  color: #333333;
}

.saveform {
  height: 93%;
  padding: 20px;
  padding-bottom: 40px;
  margin: 10px 20px;
  border-radius: 10px;
  background-color: #ffffff;
  overflow-y: scroll;
}

.table {
  flex: 1;
  overflow-y: auto;
}

.table th,
.table td {
  color: text-color-2;
  white-space: nowrap;
  line-height: 2.5rem;
  text-align: center;
  padding: 0 8px !important;
  font-size: 14px !important;
}

.select {
  width: 200px !important;
  min-width: 200px;
}

.Modal {
  width: 80% !important;
}

.saveTable th,
.saveTable td {
  padding: 0 10px !important;
  height: 2.5rem;
  line-height: 0.3rem;
  font-size: 14px;
  white-space: nowrap;
}

.delMedicalCode {
  position: relative;
  /* height: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 5px;
  border-radius: 2px;
}
.hilistName{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
.hilistName>div{
  display: flex;
  align-items: center;
  height: 20px;
}

.delMedicalCode :global(.ant-btn) {
  padding: 0 2px !important;
  flex: 1;
}

.delMedicalCode:hover {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
}

.Close {
  display: none;
  position: absolute;
  top: -15px;
  right: -10px;
  z-index: 99;
}

.delMedicalCode:hover .Close {
  display: block;
}


.saveform :global(.ant-input-disabled) {
  color: #333 !important;
}

.saveform :global(.ant-picker-input > input) {
  color: #333 !important;
}

.saveform
  :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector) {
  color: #333 !important;
}

.formQueryPopover :global(.ant-popover-inner-content){
  width:440px !important;
  padding:8px !important
}

.formQueryPopover :global(.ant-form-item){
  margin-bottom: 10px !important;
}

.timeline {
  padding: 30px 10px;
  margin-left: 160px;
  border-left: 1px solid #e1e1e1;
  position: relative;
}

.timeline::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: #2a7cf6 2px solid;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.time {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-120%, -50%);
}

.dropDown :global(.ant-spin-container){
  max-height: 450px !important;
}
.form input {
  font-size: 13px;
}
.select :global(.ant-select-selector) {
  height: 2rem !important;
  line-height: 2rem !important;
}

.select :global(.ant-select-selection-item) {
  line-height: 2rem !important;
  text-align: center;
}

.split {
  width: 280px !important;
}

.split :global(.ant-modal-body) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.split :global(.ant-modal-body) button {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0 16px !important;
  line-height: 3rem;
  font-size: 1.125rem;
  text-align: left;
}

.hint {
  color: red;
  margin-top: 27px;
}

.field :global(.ant-form-item-explain) {
  display: none;
}



@value colors: "../../colors.css";
@value primary-color, text-color-1, table-header-bg-color from colors;

.container :global(.ant-divider-horizontal) {
  margin: 0 !important;
  border-top: solid 1px table-header-bg-color !important;
}

.info {
  margin-top: 6px !important;
}

.info label {
  font-size: 1.125rem !important;
}

.info input,
.info textarea {
  color: text-color-1;
  font-size: 1rem !important;
  min-height: 2.25rem !important;
}

.info :global(.ant-form-item-control-input-content) {
  margin-left: 10px;
}

.info > :global(.ant-form-item) {
  margin-bottom: 20px !important;
}

.disease {
  display: inline-block;
  line-height: 1.875rem;
  background-color: table-header-bg-color;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.history :global(.ant-modal-body) {
  padding: 10px 20px !important;
}

.history :global(.ant-modal-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history :global(.ant-modal-body) {
  flex: 1;
  overflow: hidden;
}

.templates :global(.ant-collapse-header) {
  margin-bottom: 10px;
  color: text-color-1 !important;
  font-size: 1.125rem;
  height: 3rem !important;
  min-height: 3rem !important;
  background-color: table-header-bg-color;
  border-left: 2px solid primary-color;
  display: flex;
  flex: 1;
  position: relative;
}

.templates :global(.ant-collapse-item) {
  border: none !important;
}

.opBar {
  position: absolute;
  right: 10px;
}

.opBar :global(.ant-space-item) {
  display: flex;
}

.opBar :global(.ant-collapse-header-text) {
  flex: 1;
}

.container :global(.ant-space-align-baseline .ant-space-item:nth-child(2)) {
  flex: 1;
}

.container :global(.ant-row) {
  margin-bottom: 20px;
}

.container :global(.ant-row .ant-form-item) {
  margin-bottom: 0px;
}
.container :global(.ant-space-align-baseline + .ant-space-align-baseline) {
  margin-top: 20px;
}

.leftContent :global(.ant-spin-nested-loading) {
  overflow: auto;
}


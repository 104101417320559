.form {
  width: 810px !important;
}

.title {
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #f3f7fd;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  margin-bottom: 20px;
}

.titletext {
  padding-left: 10px;
  border-left: 2px #0052d9 solid;
  width: 108px;
  height: 18px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.examinetreatTable {
  height: calc(100% - 85px);
  overflow-y: hidden;
}

.examinetreatTable th {
  padding: 0 10px !important;
  height: 2.5rem;
  line-height: 0.3rem;
}

.examinetreatTable td {
  color: text-color-1;
  font-size: 14px;
}
.add {
  width: 146px;
  height: 32px;
  float: left;
  margin-top: 10px;
  position: relative;
}
.adds {
  width: 110px;
  height: 32px;
  float: left;
  margin-top: 10px;
  position: relative;
}

.add:hover .addmenu {
  display: block;
}

.add:active .addmenu {
  display: block;
}

.addmenu {
  display: none;
  position: absolute;
  z-index: 99;
  top: 32px;
  left: 0;
  width: 146px;
  list-style: none;
  margin: 0 auto;
  padding: 0;
}

.addmenu > li {
  border-radius: 2px;
  width: 146px;
  height: 36px;
  line-height: 36px;
  color: #666666;
  background-color: #ffffff;
}

.addmenu > li:hover {
  background-color: #f3f9fd;
}

.addmenu > li:active {
  background-color: #027aff;
  color: #ffffff;
}

.delMedicalCode {
  position: relative;
  /* height: 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 5px;
  border-radius: 2px;
  cursor: pointer;
}
.hilistName {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.hilistName > div {
  height: 20px;
  display: flex;
  align-items: center;
}

.delMedicalCode :global(.ant-btn) {
  padding: 0 2px !important;
  flex: 1;
}

.delMedicalCode:hover {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
}

.Close {
  display: none;
  position: absolute;
  top: -15px;
  right: -10px;
  z-index: 99;
}

.delMedicalCode:hover .Close {
  display: block;
}

.main :global(.ant-modal-title) {
  text-align: center;
}

.main {
  position: relative;
}
.upload {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.upload :global(.Icons_large__14hGG) {
  width: 4rem !important;
  height: 4rem !important;
  fill: #b8bcc5 !important;
}

/* 菜单样式 */
.menuWrap {
  margin: 10px 10px 0;
  height: calc(100% - 60px);
  background-color: #fff;
  overflow: auto;
}
.menuTitle {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 150px;
}
.menuTitleIcon {
  position: absolute;
  right: 10px;
  color: #027aff;
  cursor: pointer;
}
.menuContent {
  height: 35px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
}
.menuContent:hover {
  background-color: #d3dcf3;
}

/* 目录分类设置模态框 */
.modalWrap :global(.ant-modal-header) {
}
.modalWrap :global(.ant-modal-title) {
}
.modalWrap :global(.ant-modal-close-x) {
  height: 42px !important;
  line-height: 42px !important;
  color: #fff;
}
.modalWrap :global(.ant-modal-body) {
  height: 500px;
  overflow-x: auto;
  padding:16px !important
}

/* 删除文字样式 */
.iptClose {
  margin-left: 10px;
  width: 35px;
  color: #879be3;
  cursor: pointer;
}

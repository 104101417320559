.surgical {
  height: calc(100% - 5px);
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 5px;
  /* margin-top: 10px; */
}
.surgicalFrom {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
}
.surgicalFrom :global(.ant-divider-horizontal) {
  margin: 20px 0 !important;
}
.surgicalFrom :global(.ant-form-item) {
  margin-bottom: 16px !important;
}

.surgTitle {
  margin: 0;
  padding: 0;
  line-height: 40px;
  position: relative;
  top: 10px;
  left: 24px;
  font-size: 20px;
}
.moveBtn {
  position: absolute;
  right: -20px;
  top: 10px;
  z-index: 99;
}

.history :global(.ant-modal-body) {
  padding: 10px 20px !important;
}

.history :global(.ant-modal-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history :global(.ant-modal-body) {
  flex: 1;
  overflow: hidden;
}

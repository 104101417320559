@value colors: "../colors.css";
@value primary-color, tertiary-color, text-color-2, button-border-color from colors;

.consultContainer {
  width: 25px;
  height: 75px;
  background-color: #027aff;
  position: fixed;
  bottom: 20px;
  right: 0;
  color: #fff;
  z-index: 99999;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  /* cursor: pointer; */
}

.consult {
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.consult span {
  user-select: none;
}

.name {
  margin: 0 21px;
  height: 3.75rem;
  line-height: 3.75rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sider :global(.ant-layout-sider-children) {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.625rem 1.2rem;
  text-align: center;
  fill: primary-color;
  border-radius: 999px;
  background-color: #ffffff;
  cursor: pointer;
}

:global(.ant-menu-inline) {
  border-right: none !important;
}

:global(.ant-menu-inline-collapsed-tooltip) {
  display: none !important;
}

:global(.ant-badge) {
  width: 100%;
}

:global(.ant-menu-vertical) {
  border-right: none !important;
}

:global(.ant-dropdown-menu-title-content) {
  display: flex;
  flex-direction: row;
}

:global(.ant-tooltip-content > .ant-tooltip-inner) {
  background-color: #40a9ff !important;
  color: #ffffff;
}

:global(.ant-tooltip-arrow-content) {
  background-color: #40a9ff !important;
}

.menu,
.menuCollapsed {
  display: flex !important;
  align-items: center;
  width: 100% !important;
}

.menu {
  padding-left: 20px !important;
}

.menuCollapsed {
  padding: 0px;
}

.menu > :global(.ant-menu-submenu-title) {
  display: flex !important;
  align-items: center;
  width: 100% !important;
  padding: 0 !important;
}

.menu > span,
.menu > :global(.ant-menu-submenu-title) span {
  margin-left: 20px;
  overflow: hidden;
}

.menu svg,
.stack svg {
  fill: #ffffff;
}

:global(.ant-menu-submenu-popup > .ant-menu) {
  background-color: primary-color !important;
}

:global(.ant-dropdown-menu-title-content) {
  display: flex;
  flex-direction: row;
}

.stack {
  height: 100%;
  font-size: 0.875rem !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stack > span,
.stack > div {
  line-height: 1.25rem !important;
}

:global(.ant-menu-item-selected),
:global(.ant-menu-item-active) {
  background: linear-gradient(
    90deg,
    rgba(2, 56, 255, 0.6),
    transparent
  ) !important;
}

.header {
  height: 3.125rem !important;
  line-height: 3.125rem !important;
  padding: 0 20px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header :global(.ant-badge) {
  width: auto;
}

.content {
  flex: 1;
  overflow-y: hidden;
}

.content :global(.ka-wrapper),
.content :global(.ka-content) {
  height: 100%;
}

.scroll {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem !important;
  color: text-color-2;
  text-align: center;
  border: 1px solid button-border-color;
}

.breadcrumb {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.breadcrumb::-webkit-scrollbar {
  display: none;
}

.leftSidebar{
  width: 78px !important;
}

.leftSidebarContent::-webkit-scrollbar {
  width: 9px !important;
  height: 9px !important;
}

.leftSidebars::-webkit-scrollbar {
  width: 9px !important;
  height: 9px !important;
}

.item,
.current {
  line-height: 1.875rem;
  font-size: 1rem;
  box-sizing: border-box;
  padding: 0 12px;
  margin-left: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 15px;
}

.item {
  border: 1px solid button-border-color;
}

.item > span,
.current > span {
  margin-left: 10px;
}

.item {
  color: text-color-2;
}

.current {
  color: #ffffff;
  background-color: primary-color;
}

.user {
  color: text-color-2;
  font-size: 0.875rem;
  margin-left: 20px;
}

.message {
  display: flex;
  align-items: center;
}

.messageContainer {
  background-color: #fff;
  padding: 10px;
  height: 350px;
  overflow-y: auto;
}

.messageContainer p {
  margin-bottom: 0;
}

/* .messageItem{
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
text-overflow: ellipsis;
} */

.messageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.messageItem {
  width: 100%;
  white-space: warp;
  word-break: break-all;
}

.fontColor {
  color: #999;
}

.message :global(.ant-dropdown) {
  width: 400px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}

.user:hover {
  color: text-color-2;
}

.overlay {
  height: 3.125rem;
  color: text-color-2;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.overlay > svg {
  margin-right: 10px;
}

.overlay > svg path,
.overlay > svg rect,
.overlay > svg circle {
  color: text-color-2 !important;
  fill: text-color-2 !important;
}

.upgrade {
  composes: overlay;
  color: primary-color;
}

.upgrade > svg path,
.upgrade > svg rect,
.upgrade > svg circle {
  color: primary-color !important;
  fill: primary-color !important;
}

.version {
  color: primary-color;
}

.versionFa:hover .versionSonOne {
  display: none;
}

.versionSonTwo {
  display: none;
}

.versionFa:hover .versionSonTwo {
  display: inline-block;
}

.version > span {
  margin-left: 18px;
  font-size: 0.75rem;
}

::-webkit-scrollbar {
  width: 14px !important ;
  height: 14px !important;
  z-index: 999;
}

/* ::-webkit-scrollbar-track:hover {
  background: transparent;
} */

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background-color: #bedcfd !important;
  position: relative !important;
  left:100 !important
}

/* 
::-webkit-scrollbar-button {
  display: none !important;
} */
.accountList :global(.ant-tabs-nav-wrap) {
  background: #f4f8fb;
  height: 40px;
}

/* .accountList :global(.ant-tabs-tab:hover) {
background: #fff;
padding: 5px 20px;
} */

.accountList :global(.ant-tabs-tab-active) {
  background: #fff;
}

.accountList :global(.ant-tabs-tab) {
  padding: 5px 20px;
}

.accountList :global(.ant-tabs-tab + .ant-tabs-tab) {
  margin: 0;
}

.accountList :global(.ant-tabs-ink-bar) {
  position: absolute;
  background: #027aff;
  pointer-events: none;
  top: 0;
}

.accountList :global(.ant-tabs) {
  flex: 1;
}

.accountList :global(.ant-tabs-content-holder .ant-tabs-content) {
  height: 100%;
  margin-top: -15px;
}

.accountList :global(.ant-tabs-content .ant-tabs-content-top) {
  display: flex;
  flex-direction: column;
}

.accountList :global(.ant-tabs-tabpane-active) {
  display: flex;
  flex-direction: column;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: #ccc;
  opacity: 0.7;
  z-index: 99;
}

.imgWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  cursor: pointer;
}

.img {
  width: 700px;
  height: 500px;
}

.closeImg {
  padding: 0 10px;
  display: flex;
  align-items: center;
  position: absolute;
  right: -12px;
  top: -15px;
  font-size: 26px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 25px;
  z-index: 999;
  cursor: pointer;
}

.text {
  font-weight: bold;
  text-align: center;
  color: #878484;
}

.upModal :global(.ant-modal-body) {
  height: 600px;
  overflow: auto;
}

.backsMain {
  display: flex;
  flex-shrink: 0;
  flex-basis: 31.6%;
  flex-wrap: wrap;
}

.backs {
  width: 31.6%;
  height: 350px;
  /* background-color: red; */
}

.imgs {
  width: 100%;
  height: 100%;
  border-radius: 15px 15px 4px 4px;
}

.backs {
  margin-right: 20px;
  margin-bottom: 20px;
}

.backs:nth-of-type(3) {
  margin-right: 0;
}

.tipsText {
  width: 460px;
  height: 40px;
  color: #ff8345;
  background-color: rgba(255, 131, 69, 0.18);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px 52px;
}

.changePassWordModal :global(.ant-modal-close-x) {
  display: none !important;
}

.changePassWordModal :global(.ant-modal-title) {
  text-align: center;
}

.stopAction {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  /* width: 100vw;
  height: 100vh; */
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 50px;
}
.main {
  height: calc(100% - 30px);
  margin: 10px 0px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.head {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
}

.reventReport :global(.ant-table-cell) {
  line-height: 39px;
  padding: 0 10px;
}

.reventReport {
  flex: 1;
  border-radius: 10px !important;
}

.reventReport :global(.ant-table-thead > tr > th) {
  border: 1px solid #333 !important;
   border-right:none !important
}

.reventReport :global(.ant-table-thead > tr  > th:last-child)  {
   border-right: 1px solid #333 !important;
}

.reventReport :global(.ant-table-thead > tr > th)  {
   background:#fff ;
   font-weight:bold
}

.reventReport :global(.ant-table-tbody > tr > td:last-child)  {
   border-right: 1px solid #333 !important;
}

.reventReport :global(.ant-table-tbody > tr > td) {
  border: 1px solid #333 !important;
  border-top:none !important;
  border-right:none !important
}

/* 打印样式 */
.reventReports {
  flex: 1;
}

.reventReports th {
  border: 1px solid #333 !important;
  border-right: none !important;
}
.reventReports th:last-child {
  border-right: 1px solid #333 !important;
}
.reventReports td {
  border: 1px solid #333 !important;
  border-top: none !important;
  border-right: none !important;
}
.reventReports td:last-child {
  border-right: 1px solid #333 !important;
}

.container {
  display: none;
}

.container {
  content: counter(page);
}

@media print {
  .container {
    display: block;
  }
}

.desc {
  text-align: left;
}

.title {
  font-weight: bold;
  margin-left: 30px;
  font-size: 16px;
  margin-bottom: 20px;
}

.dzqmTitle {
  margin-left: 30px;
  font-size: 16px;
  margin-bottom: 20px;
}

.nodzqmTitle {
  margin-left: 50px;
  width: 260px;
  height: 48px;
  border-radius: 2px;
  background-color: rgba(240, 246, 255, 1);
  color: rgba(74, 141, 255, 1);
  border: 1px solid rgba(74, 141, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.contentTitleMain,.contentTitleMains {
  margin-top: 24px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.27);
}

.contentTitleMain :global(.ant-checkbox + span) {
 width: 86px !important;
}

.contentTitleMains :global(.ant-checkbox + span) {
 width: 200px !important;
}

.contentTitleMain :global(.ant-row) {
}

.imgWrap {
  width: 310px;
  height: 250px;
  /* background-image: url(../user/setting/img/JEML.png); */
}

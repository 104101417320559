.container {
  background-color: #fff;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
  overflow-y: auto;
}

.mainContent {
  width: 270px;
  height: 218px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 2px;
}

.pic {
  width: 102px;
  height: 102px;
  margin: 28px 0 10px 0;
}

.name {
  font-size: 20px;
  color: #333333;
  margin-bottom: 10px;
  font-weight: bold;
}

.title {
  font-size: 14px;
  color: #101010;
}

.noMainContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.noMessagePic {
  width: 303px;
  height: 303px;
}

.ButtonStyle {
  width: 131px;
  height: 45px;
  font-size: 20px;
  color: #ffffff;
  background-color: #2a7cf6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
  cursor: pointer;
}

.authCellModal {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.authCellModal span:first-child {
  width: 70%;
}

.authBtnModal {
  width: 110px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 5px;
  background-color: #408cff;
  cursor: pointer;
}

.authCellModal:nth-child(2) .authBtnModal {
  background-color: #0ebc6a;
}

.authCellModal:last-child .authBtnModal {
  background-color: #ffb145;
}

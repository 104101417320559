.drugStatic {
  flex: 1;
  border-radius: 10px !important;
}

.content :global(.ant-checkbox-wrapper) {
  display: flex !important;
  align-items: baseline !important;
}

/* 打印样式 */
.drugStatics {
  flex: 1;
}
.drugStatics th {
  border: 1px solid #333 !important;
  border-right: none !important;
}
.drugStatics th:last-child {
  border-right: 1px solid #333 !important;
}
.drugStatics td {
  border: 1px solid #333 !important;
  border-top: none !important;
  border-right: none !important;
}
.drugStatics td:last-child {
  border-right: 1px solid #333 !important;
}

.content {
  height: calc(100% - 30px);
  margin: 10px 0px;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.drugStaticModal :global(.ant-table-cell) {
  line-height: 39px;
  padding: 0 !important;
}

.drugStaticModal :global(.ant-table-row):last-of-type {
  background-color: #d9f2ff;
}

.modal :global(.ant-table-cell) {
  line-height: 39px;
}

.modal :global(.ant-modal-body) {
  line-height: 39px;
  /* padding: 0 !important; */
}

.modal :global(.ant-modal-content) {
  height: 660px !important;
}

.modal :global(.ant-table-content) {
  height: 480px !important;
}

.modal :global(.ant-modal-content .ant-row) {
  height: 50px !important;
}

.total {
  background-color: #d9f2ff;
}

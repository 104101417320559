@value colors: "../../colors.css";
@value primary-color, text-color-1, text-color-2, table-header-bg-color from colors;

.disease {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.875rem;
  background-color: table-header-bg-color;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}
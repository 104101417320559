@value colors: "../../../colors.css";
@value primary-color, tertiary-color, text-color-1, text-color-2, table-header-bg-color from colors;

.container :global(.ant-tabs-content) {
  height: 100%;
}
.recipes {
  flex: 1;
  padding: 0 10px;
  overflow: auto;
}

.carouseCol :global(.ant-popover){
  width:50% !important;

}
.fadeIn {
  opacity: 0;
  animation-name: fadeInAnimation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.carouselDiv{
  display: inline-block;
  overflow: hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
  width:100%;
}

.category,
.amount {
  color: text-color-1;
  font-size: 1rem;
  line-height: 1rem;
}

.category {
  padding:5px!important;
  white-space: nowrap;
}

.amount {
  padding-right: 0 !important;
  padding-left: 0 !important;
  /* white-space: nowrap; */
}

.close {
  width: 10px;
  height: 10px;
  padding: 10px !important;
  cursor: pointer;
}

.add {
  margin: 5px 36px 5px 36px;
}

.menus {
  padding: 24px !important;
}

.label {
  color: text-color-1;
  font-size: 1rem;
}

.menu {
  color: text-color-2;
  text-align: center;
  font-size: 1rem;
  line-height: 3rem;
  font-weight: bold;
  margin-top: 10px;
  border: solid 1px #eee;
  cursor: pointer;
}

.menu:hover {
  color: #fff;
  background-color: primary-color;
}

.recipes :global(.ant-table-selection-column) {
  display: none;
}

.history :global(.ant-card) {
  margin: 10px;
}

.history :global(.ant-card-head) {
  min-height: 2rem;
  padding: 0px 20px;
  background-color: tertiary-color;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: solid 3px primary-color;
}

.history :global(.ant-card-head-title) {
  font-size: 1.125rem;
  color: text-color-1;
  line-height: 2rem;
  height: 2rem;
  padding: 0px;
}

.history :global(.ant-card-body) {
  color: text-color-2;
  font-size: 1rem;
  padding: 10px 20px;
}

.history :global(.ant-modal-body) {
  padding: 10px 20px !important;
}

.history :global(.ant-modal-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history :global(.ant-modal-body) {
  flex: 1;
  overflow: hidden;
}
.drawer {
  height: calc(100% - 140px);
  position: fixed;
  top: 60px;
  z-index: 99;
}
.drawerContainer {
  width: 360px;
  height: 100%;
  background: #fff;
  position: relative;
}

.drawerLeft {
  position: absolute;
  left: -40px;
  height: 100%;
  width: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.drawerBtn {
  writing-mode: vertical-rl;
  cursor: pointer;
  padding: 20px 0;
  padding-bottom: 10px;
  width: 25px;
  line-height: 25px;
  border-radius: 5px 0 0 5px;
  color: #fff;
  letter-spacing: 0.5em;
}
.drawerBtnIcon {
  /* margin-top: 10px; */
  /* margin-left: 20px;
  vertical-align: middle; */
  position: relative;
  left: 1px;
}

.container :global(.ant-tabs-tabpane) {
  overflow-y: auto;
}


.ModalContent :global(.ant-table-tbody > tr > td){
  padding: 8px 16px!important;

}
.ModalContent :global(tr.ant-table-measure-row){
  visibility: collapse!important;

}

/* .fyyq :global(.ant-col):last-child{
  margin-left: 114px;
} */

.fyyqFirst :global(.ant-col):last-child{
  margin-left: 114px;
}

@value colors: "../../colors.css";
@value primary-color, text-color-1, text-color-2, text-color-3, input-border-color from colors;

.categories > :global(.ant-form-item-control) {
  border: solid 1px input-border-color;
  padding: 10px;
}

.categories :global(.ant-row) {
    margin: 0 !important;
}

.categories :global(.ant-form-item-control-input-content) {
  display: flex !important;
}

.categories :global(.ant-form-item-control-input-content) > div {
  flex-grow: 1;
}

.column {
  display: flex !important;
  flex-direction: column;
}

.column > :global(.ant-checkbox-wrapper) {
  margin: 0 !important;
}

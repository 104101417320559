.content {
    height: calc(100% - 10px);
    margin-top: 10px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
}

.nopitest {
    flex: 1;
    border-radius: 10px !important;
}

.button {
    border: none;
}

.button :global(.svg) {
    fill: #1CD0BD;
    border: none;
}
.putstorage {
  height: 100%;
}

.notificationContent :global(.ant-notification-topRight){
  right: 40% !important;
}

.putstorage :global(.ant-table-body){
  max-height: 450px;
  overflow: auto !important; 
}

.copy span {
   margin-left: 0 !important;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.head {
  background-color: #ffffff;
  margin-left: 19px;
  margin-right: 21px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 3px;
  padding-bottom: 5px;
  padding-right: 20px;
  position: relative !important;
  height: 38px;
  box-shadow: 0px 2px 0px 0px #027aff;
}

.content {
  padding: 10px 20px 80px 10px;
  overflow-y: scroll;
  height: 100%;
  margin: 12px 20px;
  background: #fff;
}

.gray {
  background-color: #f4f4f4;
}

.pagination {
  height: 50px;
  position: relative;
}

.puttable {
  height: 85%;
}

.puttable th {
  padding: 0 10px !important;
  height: 2.5rem;
  line-height: 0.3rem;
}
.select {
  width: 200px !important;
  min-width: 200px;
}
.total {
  background-color: #d9f2ff !important;
}

.form :global(.ant-form-item) {
  padding: 0 !important;
}

.content {
  height: calc(100% - 40px);
  margin: 10px 0;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.echarts {
  /* height: calc(100% - 60px); */
  height: 94%;
  width: 100%;
  overflow-y:hidden ;
  overflow-x:auto ;
}

.tabs:global(.ant-tabs) {
  height: 100%;
  /* background-color: #fff !important; */
  margin: 10px 20px;
}

.tabs :global(.ant-tabs-nav) {
  margin: 0 !important;
}

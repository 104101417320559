.content {
  height: calc(100% - 10px);
  margin-top: 10px;
  padding:0 15px 0 0;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.inspect {
  flex: 1;
  margin-top: 15px;
}

.inspectTable {
  height: 81.5%;
  overflow: auto;
  margin-top: 15px;
}

.inspectTable tr > th {
  padding: 0 10px !important;
  height: 2.5rem;
  font-size: 16px;
}
.inspectTable tr:nth-child(2) > th {
  font-size: 12px !important;
}

.inspectTable tr th {
  padding: 0 10px !important;
  height: 2.5rem;
  font-size: 16px;
}

.inspectTable :global(.ant-table-tbody > tr > td) {
  padding: 0 !important;
  height: 40px;
}

.inspectTable :global(.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td),
.ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #9ED7FF !important;
}

.inspectTable .ant-table-fixed .ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #9ED7FF !important;
}

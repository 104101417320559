@value colors: "../../colors.css";
@value text-color-1, text-color-2 from colors;

.table th,
.table td {
  color: text-color-1;
  white-space: nowrap;
  font-size: 1rem;
}

.table :global(.ant-table-column-sorters) {
  padding: 0px !important;
}

@media print {
  .table th,
  .table td {
    font-size: 0.75rem !important;
  }
}

.table th {
  padding: 0 8px !important;
  height: 2.5rem;
  line-height: 2.5rem;
  white-space: nowrap;
}

.table td {
  color: text-color-2 !important;
  padding: 0 10px !important;
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 2rem;
  white-space: nowrap;
}

.table input {
  padding: 4px;
}

.table {
  flex: 1;
  /* max-width: 100%; */
  overflow-y: auto;
}

.table :global(.ant-table-sticky-scroll-bar) {
  display: none;
}

.table :global(.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td),
.ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #9ED7FF !important;
}

.table .ant-table-fixed .ant-table-row-hover,
.ant-table-row-hover > td {
  background-color: #9ED7FF !important;
}

/* .table :global(.ant-table-header){
  overflow: visible !important;
} */

.input {
  position: relative;
}

.input :global(.ant-form-item-explain) {
  position: absolute;
  top: 100%;
  font-size: 0.5rem !important;
}

.wrapper {
  height: 2rem;
  cursor: pointer;
}

.form :global(.ant-form-item){
    padding:0 !important
}

.total {
  background-color: #d9f2ff !important;
}

.total :global(.ant-table-cell-fix-right){
  background-color: #d9f2ff !important;
}
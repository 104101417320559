.container {
  height: 100%;
}

.main {
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 20px;
}

.left {
  width: 65%;
  overflow-y: scroll;
}

.right {
  width: 354px;
  height: 725px;
  overflow-y: auto;
  background: #f4f4f4;
  background: url('../../images/back.png') no-repeat;
  background-size: 100%;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 80px;
}

.right :global(.ant-carousel) {
  width: 304px !important;
  margin-left: 1px;
}

.rightTitle {
  height: 48px;
  margin-top: 54px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightTitleIcon {
  color: #999999;
  width: 24px;
  height: 24px;
  position: relative;
  left: -90px;
}

.rightTitleText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topPic {
  width: 330px;
  height: 180px;
}

.topMain {
  background-color: #f4f4f4;
  width: 304px;
  height: 56.5%;
  border-radius: 0 0 8% 8%;
}
.topMainContent {
  max-height: 416px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  text-align: left;
  position: relative;
  top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topMainOneContent {
  width: 280px;
  background-color: #fff;
  border-radius: 9px;
  padding: 0 16px;
  margin-bottom: 10px;
}

.topMainTitle {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 16px 0 10px 0;
  font-size: 20px;
  font-weight: bold;
}

.topMainTitleTag {
  padding: 0 0 12px 0;
  border-bottom: 1px solid #eeeeee;
}

.topMainTitleTagLabel {
  display: inline-block;
  height: 24px;
  padding: 3px 10px;
  border-radius: 26px;
  font-size: 12px;
  color: rgba(252, 156, 65, 1);
  background-color: #ffeed8;
  margin: 0 10px 6px 0 !important;
}

.topMainPhone {
  border-bottom: 1px solid #eeeeee;
  padding: 14px 0;
  font-size: 15px;
  display: flex;
}

.topMainPhoneLeft {
  display: flex;
  width: 94%;
  padding-right: 20px;
}

.topMainPhoneRight {
  width: 24px;
  height: 24px;
  background-color: #ecf8f0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topMainAreaRight {
  width: 24px;
  height: 24px;
  background-color: #ecf2fe;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topMainArea {
  padding: 14px 0;
  font-size: 15px;
  display: flex;
}

.topMainAreaLeft {
  display: flex;
  width: 94%;
  padding-right: 20px;
}

.topMainAreaLeftTitle {
  width: 75px;
}

.topMainAreaLeftContent {
  flex: 1;
}

.topMainTwoContent {
  width: 280px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 9px;
  padding: 0 16px;
}

.topMainTwoContentTitle {
  color: #101010;
  font-weight: bold;
  font-size: 17px;
  padding: 16px 0 14px 0;
}

.topMainTwoContentTitle::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 14px;
  background: #0ebc6a;
  position: relative;
  left: -16px;
}

.topMainTwoContentContent {
  font-size: 15px;
  line-height: 24px;
  padding-bottom: 6px;
}

.rightMain {
  height: 100%;
  overflow-y: scroll;
}

.labelColor {
  display: inline-block;
  font-size: 14px;
  line-height: 1.875rem;
  background-color: #ffeed8;
  padding: 0 10px;
  margin-right: 10px;
  color: #fc9c41;
  margin-bottom: 5px;
  position: relative;
}

.closeIcon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  right: -7px;
  top: -4px;
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picColor {
  display: inline-block;
  width: 100px;
  height: 100px;
  font-size: 14px;
  line-height: 1.875rem;
  background-color: #999999;
  padding: 0 10px;
  margin: 10px 10px 5px 0;
  position: relative;
}

.upload {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.uploadButton {
  width: 88px;
  height: 32px;
  border: 1px solid #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  font-size: 14px;
  cursor: pointer;
}

.uploadText {
  font-size: 14px;
  color: #d1d0d1;
}

.right :global(.slick-dots li button) {
  display: none !important;
}

.imgWrap {
  position: relative;
  margin: 10px 10px 0px 10px;
  width: 100px;
  height: 100px;
  user-select: none;
}
.img {
  width: 100px;
  height: 100px;
  cursor: pointer;
}
.imgClose {
  position: absolute;
  right: -9px;
  top: -10px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 15px;
  color: #fff;
  background-color: #999999;
  border-radius: 50%;
  cursor: pointer;
}

.content {
  height: 100%;
  margin: 10px 20px;
}

.tabs {
  margin: 10px 20px !important;
  background-color: #fff;
}

.tabs :global(.ant-tabs-content) {
  height: 100%;
}

.tabs :global(.ant-table-body) {
  min-height: 410px !important;
}

.tabs :global(.ant-tabs-content-holder){
  overflow: auto !important;
}

.backsButtton :global(.ant-btn-primary){
  background-color: #FFAC51  !important;
  border: 1px solid #FFAC51;
}

.backsButtton :global(.ant-btn-primary:hover){
  background-color: #FFAC51  !important;
  border: 1px solid #FFAC51;
}
.main {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    height: 100%;
    overflow-y: hidden;
}

.main :global(.ant-tabs-content-holder) {
    margin-top: 10px;
}

.total {
    background-color: #d9f2ff !important;
}

.container {
    background-color: #fff;
    height: 100%;
    border-radius: 8px;
    padding: 10px;
}

.wrap {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    width: 100%;
    height: calc(100% - 0px);
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.coupons {
    border-radius: 4px;
    height: 100px;
    background-color: #fff;
    overflow: hidden;
    border: 1px solid rgba(42, 124, 246, 1);
    font-size: 14px;
}

.coupons_title {
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 32px;
    background-color: rgba(206, 224, 255, 1);
}

.coupons_title_icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
}

.coupons_content {
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main {
  height: 100%;
}

.tabs:global(.ant-tabs) {
  height: 100%;
  background-color: #fff !important;
  padding: 0 20px;
  border-radius: 10px;
}

.tabs :global(.ant-tabs-nav) {
  margin: 0 !important;
}



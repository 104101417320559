.highlight {
  background-color: #eef8ff;
}

.total {
  background-color: #d9f2ff;
}

.table th {
  color: #666 !important;
  font-size: 1rem;
  height: 3.75rem;
  line-height: 3.75rem;
  padding: 0 10px !important;
}

.table th:first-child {
  padding: 0 !important;
}

.table td {
  color: #666 !important;
  font-size: 1rem;
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0 10px !important;
}
/* 打印样式 */
.tables th {
  color: #666 !important;
  border: 1px solid #333 !important;
  border-right: none !important;
  font-size: 1rem;
  height: 3.75rem;
  line-height: 3.75rem;
  padding: 0 10px !important;
}
.tables th:last-child {
  border-right: 1px solid #333 !important;
}

.tables th:first-child {
  padding: 0 !important;
}

.tables td {
  color: #666 !important;
  border: 1px solid #333 !important;
  border-top: none !important;
  border-right: none !important;
  font-size: 1rem;
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0 10px !important;
}
.tables td:last-child  {
  border-right: 1px solid #333 !important;
}

.total > td {
  color: #027aff !important;
}

.total > td:first-child {
  font-weight: bold !important;
}
.tabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab) {
  position: relative;
  margin: 0 !important;
  padding: 0 24px;
  line-height: 3rem;
}

.tabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab-active):before {
  position: absolute;
  content: "";
  left: 8px;
  top: 0;
  bottom: 0;
  width: 24px;
  transform: skewX(-15deg);
}

.tabs
  > :global(.ant-tabs-nav)
  :global(.ant-tabs-tab-active):first-child:before {
  left: 0;
  width: 30px;
  border-top-left-radius: 10px;
  transform: skewX(0deg);
}

.tabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab-active):after {
  position: absolute;
  content: "";
  right: 8px;
  top: 0;
  bottom: 0;
  width: 24px;
  transform: skewX(15deg);
}

.tabs > :global(.ant-tabs-nav) :global(.ant-tabs-tab-btn) {
  position: relative;
  font-size: 1.25rem;
  transition: none;
  z-index: 99;
}

.tabs :global(.ant-tabs-ink-bar) {
  display: none;
}

.tabs > :global(.ant-tabs-content-holder) {
  flex: 1;
}

.tabs > :global(.ant-tabs-content-holder) > :global(.ant-tabs-content) {
  height: 100%;
  overflow: hidden;
}

.teethTable {
  min-height: 50px;
  width: 135px !important;
}
.teethTable > tr,
td {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
.teethTable > tr > td > span {
  display: inline-block;
  width: 45px !important;
  line-height: 22px;
  height: 22px;
  margin: 0;
  padding: 0;
}
.teethTable > th,
.teethTable > tr td {
  width: 65px !important;
  /* min-height: 25px; */
  height: 20px;
  max-height: 100px;
  border: 1px solid #3195c5;
}
.teethTable > tr td:first-child {
  border-left: 0;
}
.teethTable > tr td:last-child {
  border-right: 0;
}
.teethTable > tr:first-child td,
tr:first-child th {
  border-top: 0px;
}
.teethTable > tr:last-child td {
  border-bottom: 0px;
}

.teethTable > tr td:first-child {
  text-align: right;
  vertical-align: bottom;
}

.tabContent {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tabContent ul {
  list-style: none;
  margin: 5px;
  padding: 0;
  margin-left: 10px;
  display: flex;
  border-bottom: 1px solid rgba(224, 224, 224, 0.644);
}

.tabContent li {
  text-align: center;
  color: #000;
  background: #fff;
  position: relative;
  padding: 5px 10px;
}

#active {
  color: #027aff;
  border-bottom: 2px solid #027aff;
  font-weight: 400;
}

/* #active:not(:last-child)::after {
  content: " ";
  width: 0px;
  height: 0px;
  border-bottom: 50px solid #062a8f;
  border-right: 2.25em solid transparent;
  position: absolute;
  z-index: 999;
  right: -2.25em;
  top: 0;
}

#active:last-child::after {
  content: " ";
  width: 0px;
  height: 0px;
  border-bottom: 2.25em solid #07648d;
  border-left: 2.25em solid transparent;
  position: absolute;
  z-index: 999;
  left: -2.25em;
  top: 0;
} */

.showItem {
  display: block;
  padding: 10px;
}

.hideItem {
  display: none;
}

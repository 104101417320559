.container {
  position: relative;
  height: 100%;
  padding: 10px 20px;
}

.containerP {
  position: relative;
  height: 100%;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.containerP :global(.ant-tabs-nav) {
  margin: 0 !important;
}

.containerP :global(.ant-tabs-content) {
  height: 100%;
}
.containerP :global(.ant-tabs-nav-wrap) {
  padding-left: 30px;
}
.addFollowUp {
  position: absolute;
  right: 25px;
  top: 15px;
  z-index: 999;
}
.wrap {
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  width: 100%;
  height: calc(100% - 10px);
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

/* 详情对话框 */
.detailModalWrap :global(.ant-modal-title) {
  font-weight: 700;
  color: #000;
}
.detailWrap {
  padding: 10px 0 0 10px;
  margin-bottom: 20px;
  height: 100px;
  /* border: 1px solid #ccc; */
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
}

.detailContentTitle {
  margin-bottom: 5px;
  font-weight: 700;
  color: #101027;
  font-size: 16px;
}

.detailWrap :global(.ant-row) {
  margin-bottom: 5px !important;
}

.table :global(.ant-spin-container) {
  min-width: 1366px !important;
}

.form :global(.ant-form-item) {
  padding: 0 !important;
}

.content {
  height: calc(100% - 30px);
  margin: 10px 0px;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.total {
  background-color: #d9f2ff;
}

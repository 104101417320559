@value colors: "../../../colors.css";
@value primary-color, text-color-1, text-color-2, button-border-color from colors;

.bar input,
.form input {
  color: text-color-1;
  font-size: 1rem !important;
  height: 2rem !important;
  padding: 2px 6px !important;
}

.bar :global(.ant-input-affix-wrapper),
.form :global(.ant-input-affix-wrapper) {
  padding: 0 11px;
}

.formItem :global(.ant-form-item) {
  height: 40px;
}

.bar input::placeholder {
  font-size: 1rem;
}

.form :global(.ant-form-item) {
  margin-bottom: 10px !important;
}

.form label {
  color: text-color-2 !important;
  font-size: 1rem !important;
}

.form :global(.ant-form-item-label) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}

.form :global(.ant-form-item-control) {
  position: relative;
}

.form :global(.ant-form-item-explain) {
  position: absolute;
  top: 2rem;
  z-index: 99;
}
.form :global(.ant-picker) {
  height: 2rem;
}

.stopAction{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 50px;
}

/* 今日预约 */
.toDayConsultContainer{   
  display: flex;
  width: 25px;
  height: 120px;
  background-color: #027AFF;
  position: fixed;
  bottom: calc(50% - 160px);
  right: 0;
  color: #fff;
  z-index: 99999;
  overflow: hidden;
  border-radius: 5px 0 0 5px;
  /* cursor: pointer; */
}
.contentWrap {
  margin-top: 5px;
  border-bottom: 1px dashed  #ccc;
}
.contentName {
  display: flex;
  justify-content: space-between;
}

.consult{
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}


.consult span {
  user-select: none;
}

.modal {
  position: absolute;
    right: 0;
    bottom: calc(50% - 95px);
    padding: 10px;
    width: 250px;
    height: 300px;
    background-color: #fff;
    box-shadow: 2px 1px 10px 4px rgba(000, 000, 000, 0.24);
    z-index: 999;

}
.listWrap {
  height: 255px;
  overflow-x: auto;
}

.rowBgColor {
  background-color: #9ED7FF;
}
.marginTop10 {
  margin-top: 10px;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
  border: 10px
}

.ant-table-cell-row-hover {
  background: none !important;
}
.ModalContent :global(.ant-table-tbody > tr > td){
  padding: 8px 16px!important;

}
.ModalContent :global(tr.ant-table-measure-row){
  visibility: collapse!important;

}

.modalCancel :global(.ant-modal-close-x) {
  display: none !important;
}
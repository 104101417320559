.total {
  background-color: #d9f2ff !important;
}

.fixed {
  display: table-cell;
  background-color: #d9f2ff !important;
}

.label {
  width: 18px;
  height: 18px;
  padding: 3px;
  margin-left: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table {
  height: 100%;
  overflow: hidden;
}

.table :global(.ant-table-body) {
  height: 39rem !important;
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  overflow-y: hidden;
}
.main :global(.ant-tabs-content-holder) {
  margin-top: 10px;
}

.container {
  background-color: #fff;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
}

.settlementInquiryModal :global(.ant-modal-body) {
  height: 650px !important;
}



.card {
  margin-bottom: 10px !important;
}

.card :global(.ant-card-head),
.card :global(.ant-card-head-title) {
  height: 2rem;
  line-height: 2rem;
  min-height: 2rem;
  padding: 0 !important;
}

.card :global(.ant-card-body) {
  fontsize: 1rem;
  padding: 10px 20px !important;
  min-height: 2.625rem;
}

.medicalOverviewTable {
  width: 100%;
  border: 1px solid #333;
}
.medicalOverviewTable > th {
  border: 1px solid #333;
}

.medicalOverviewTable th {
  border: 1px solid #333;
  border-collapse: collapse;
  text-align: center;
  line-height: 20px;
}

.medicalOverviewTable td {
  border: 1px solid #333;
  border-collapse: collapse;
  text-align: center;
  line-height: 20px;
}

.medicalOverviewTable .tableRightBottom:last-child {
  border-bottom: none;
}

.medicalOverviewTable {
  max-height: 88%;
}

.medicalOverviewContents {
   max-height: 88%;
  overflow: auto;
}

.medicalOverviewContentScroll {
  height: 100px;
  overflow: auto;
}

.tableRightBottom {
  border-bottom: 1px solid #333;
  width: 100%;
  /* height: 40px; */
  line-height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table th {
  white-space: nowrap;
}

.leftTableBack :global(.ant-table-thead > tr > th){
  background: rgb(196, 223, 246) !important;
}


.leftTableBack :global(.ant-table-row >.ant-table-cell:nth-of-type(2) ){
   width:4rem !important;
}

.leftTableBack :global(.ant-table-row >.ant-table-cell:nth-of-type(3) ){
   width:5.4rem !important;
}

.prePay {
  height: 100%;
}
.ybLeft {
  max-height:38%;
  overflow:auto
}

.print {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
}

.title {
  position: absolute;
  margin-left: 10px;
  top: -30px;
  width: 310px;
  font-style: italic;
  color: green;
}
.footer {
  position: absolute;
  bottom: 0px;
  right: -60px;
  display: flex;
  width: 100%;
}

.urgencyTips {
  color: red;
  font-weight: 600;
}

.membersInfoWrap {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 20px;
  width: 200px;
  height: 200px;
  text-align: center;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 5px 0 #ccc;
}
.infoWrap {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.infoIcon {
  margin-top: 3px;
  font-style: 20px;
  color: #f98e35;
}
.infoText {
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
}
.footerBtn {
  display: flex;
  justify-content: end;
  margin-top: 50px;
}

.selectIcon {
  position: relative;
}

.selectIcon ::after {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 999;
  left: 24px;
  top: -6px;
  background: url('./assets/back.png') #fff;
  background-size: cover;
  border-radius: 50%;
}

.modalContent :global(.ant-checkbox + span) {
  width: 86px !important;
  color: #333 !important;
}

.modalContent :global(.ant-modal-body) {
  padding: 24px 0 !important;
}

.popverConent {
  width: 387px;
  display: flex;
  justify-content: space-evenly;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.popverConentImgMain {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popverConentImgSelect {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: #eaf2ff;
  text-align: center;
  border: 2px solid #2d7bff;
}

.popverConentImg {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioStyle :global(.ant-radio-wrapper) {
  width: 120px !important;
  height: 60px !important;
  padding-top: 6px !important;
}

.prePay :global(.ant-tag-checkable-checked) {
  background-color: #e7f0ff !important;
  border: solid 1px #2d7bff !important;
}

.prePay :global(.anticon .anticon-dollar-circle) {
  width: 34px !important;
  background-color: #fff !important;
  height: 34px !important;
}

.paymentWay :global(.ant-space-item) {
  margin-right: -8px;
}

.paymentWay :global(.ant-space-item:nth-of-type(1)) {
  margin-left: 0px !important;
}

.imgWrap {
  width: 310px;
  height: 250px;
  background-image: url(../../user/setting/img/JEML.png);
}

.text {
  font-style: oblique;
  color: #949595;
}

.content {
  height: calc(100% - 115px);
  padding: 20px;
  background: #ffffff;
  border-radius: 5px !important;
  display: flex;
  flex-direction: column;
}
.uploadButton {
  width: 88px;
  height: 32px;
  border: 1px solid #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  font-size: 14px;
  cursor: pointer;
}
.header {
  height: 100px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .Icons {
  background: url("./assets/appealReady.svg") no-repeat;
  background-size: 100% 100%;
} */
.card {
  background: #fff;
  border-radius: 5px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.card div {
  flex: 1;
  overflow: hidden;
}

.card span {
  width: 200px;
}

.card + .card {
  margin-left: 10px;
}

.AgencyInspection {
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.AgencyInspectionTable {
  height: 85%;
  overflow: auto;
}

.AgencyInspectionTable th {
  padding: 0 10px !important;
  height: 2.5rem;
  font-size: 16px;
  white-space: nowrap;
}

.AgencyInspectionTable tr th {
  padding: 0 10px !important;
  height: 2.5rem;
  font-size: 16px;
  white-space: nowrap;
}

.AgencyInspectionTable :global(.ant-table-tbody > tr > td) {
  padding: 0 !important;
  height: 40px;
}

.select {
  width: 200px !important;
}

.notShow {
  display: none;
}
.detailMain {
  display: flex;
}
.title {
  font-weight: 600;
  font-size: 16px;
}

.itemContent {
  margin-top: 5px;
}
.detail :global(.ant-row .ant-form-item) {
  margin-bottom: 0;
}

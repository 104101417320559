.main {
  padding: 5px;
  height: 100%;
}
.main :global(.ant-row) {
  margin: 5px 0 !important;
}

.table {
  height: calc(100% - 155px);
  overflow: auto;
}


.query {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 45px;
}

.patientInfo {
  display: flex;
}

.qrMessageModal :global(.ant-modal-body) {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 380px;
}

.QrCodeTitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
}

.QrCodeSubTitle {
  color: #2a7cf6;
  font-size: 16px;
  margin-bottom: 10px;
}

.QrCodeMain {
  width: 220px;
  height: 220px;
  border: 1px solid rgba(187, 187, 187, 1);
}

.detailModal :global(.ant-modal-body) {
  padding: 0 24px 24px 24px;
}

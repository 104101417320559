@value colors: "../../../colors.css";
@value primary-color, text-color-1, text-color-2, table-header-bg-color from colors;

.container :global(.ant-divider-horizontal) {
  margin: 0 !important;
  border-top: solid 1px table-header-bg-color !important;
}

.info {
  margin-top: 6px !important;
}
.info label {
  color: text-color-2;
  font-size: 1rem !important;
}
.infos {
  margin-top: 6px !important;
}
.infos label {
  color: text-color-2;
  font-size: 1rem !important;
}
.patient label,
.doctor label {
  color: text-color-2;
  font-size: 0.875rem !important;
}

.info input,
.info textarea {
  color: text-color-1;
  font-size: 1rem !important;
  height: 2rem;
  min-height: 2rem !important;
}

.info :global(.ant-form-item-control-input-content) {
  margin-left: 10px;
}
.infos input,
.infos textarea {
  color: text-color-1;
  font-size: 1rem !important;
  height: 2rem;
  min-height: 2rem !important;
}

.infos :global(.ant-form-item-control-input-content) {
  margin-left: 10px;
}


.patient :global(.ant-input-affix-wrapper) {
  height: 2rem !important;
  margin-left: 10px !important;
}

.info > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}
.infos > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}


.patient > :global(.ant-form-item),
.doctor > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}

.disease {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.875rem;
  background-color: table-header-bg-color;
  padding: 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.history :global(.ant-modal-body) {
  padding: 10px 20px !important;
}

.history :global(.ant-modal-content) {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.history :global(.ant-modal-body) {
  flex: 1;
  overflow: hidden;
}

.templates :global(.ant-collapse-header) {
  margin-top: 10px;
  color: text-color-1 !important;
  font-size: 1rem;
  height: 3rem !important;
  min-height: 3rem !important;
  background-color: table-header-bg-color;
  border-left: 2px solid primary-color;
  display: flex;
}

.itemList :global(.ant-row) {
  margin-bottom: 10px;
}

.itemList :global(.ant-row .ant-form-item) {
  margin-bottom: 0px;
}

.itemList :global(.ant-space-align-baseline + .ant-space-align-baseline) {
  margin-top: 10px;
}

.itemList :global(.ant-space) {
  margin-top: 10px;
}

/* .itemList :global(.ant-form-item-control){
 margin-bottom: 0 !important;
} */

.templates :global(.ant-collapse-item) {
  border: none !important;
}

.info :global(.ant-checkbox-wrapper) {
  margin-left: 0 !important;
  margin-right: 8 !important;
}

.info :global(.ant-form-item) {
  margin-bottom: 0 !important;
}

.info :global(.ant-form-item-control) {
  margin-bottom: 10px !important;
}


.infos :global(.ant-checkbox-wrapper) {
  margin-left: 0 !important;
  margin-right: 8 !important;
}

.infos :global(.ant-form-item) {
  margin-bottom: 0 !important;
}

.infos :global(.ant-form-item-control) {
  margin-bottom: 0px !important;
}


.doctor :global(.ant-row) {
  flex-flow: row nowrap;
}

.doctor :global(.ant-form-item-control) {
  overflow: hidden;
}

.container :global(.ant-space-align-baseline .ant-space-item:nth-child(2)) {
  flex: 1;
}

.content {
  width: 100%;
  overflow-y: auto;
}

.historyContainer {
  height: calc(100% - 115px);
  overflow: auto;
}

.historyContainer :global(.ant-collapse-content) {
  background-color: #fafafa;
}

.historyContainer :global(.ant-collapse-item) {
  background-color: #fff;
}

.historyItem {
  display: flex;
  align-items: flex-start;
}

.historyItem div:last-child {
  flex: 1;
}
.checkGroup :global(.ant-input-group-addon) {
  padding: 0 5px;
}

.checkGroup :global(.ant-input) {
  padding: 2px 5px;
}

.toothTabs {
  height: 100%;
}

.toothTabs :global(.ant-tabs-content) {
  height: 100%;
}

.info :global(.ant-form-item-explain-error) {
  margin-left: 9px !important;
}

/*.info :global(.ant-select-selector) {*/
/*  height: 35px !important;*/
/*}*/

/*.info :global(.ant-input) {*/
/*  height: 35px !important;*/
/*}*/

.modal :global(.ant-modal-close-x) {
  display: none !important;
}

/* .itemList :global(.ant-form-item-label > label) {
  margin-top: 26px !important;
} */

.itemList :nth-of-type(1) :global(.ant-form-item-label > label) {
  margin-top: 24px !important;
}

.itemList :nth-of-type(2) :global(.ant-form-item-label > label) {
  margin-top: 24px !important;
}
.itemList :nth-of-type(4) :global(.ant-form-item-label > label) {
  margin-top: 24px !important;
}
.itemList :nth-of-type(5) :global(.ant-form-item-label > label) {
  margin-top: 24px !important;
}
.itemList :nth-of-type(6) :global(.ant-form-item-label > label) {
  margin-top: 24px !important;
}

.bsStyle :global(.ant-form-item-control) {
  margin-bottom: 6px !important;
}


.sfTable{
  width: 798px;
  overflow-x: auto;
}
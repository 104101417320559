@value colors: "../../../../colors.css";
@value primary-color, text-color-1, text-color-2, table-header-bg-color from colors;

.form input {
  padding: 2px 4px;
}

.box {
  padding: 0 20px;
  margin-bottom: 20px;
}

.contater {
  border-bottom: 1px solid #f1f1f1;
}

.container {
  border-bottom: 1px solid #f1f1f1;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.titles {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 14px;
}

.info label {
  color: text-color-2;
  font-size: 1rem !important;
}

.info input,
.info textarea {
  color: text-color-1;
  font-size: 1rem !important;
  height: 2rem;
  min-height: 2rem !important;
}

.info > :global(.ant-form-item) {
  margin-bottom: 10px !important;
}
.itemList :global(.ant-row) {
  margin-bottom: 10px;
}

.itemList :global(.ant-row .ant-form-item) {
  margin-bottom: 0px;
}

.itemList :global(.ant-space-align-baseline + .ant-space-align-baseline) {
  margin-top: 10px;
}

.batchModal :global(.ant-modal-body) {
  height: 600px;
  padding: 15px;
}

.batchModal :global(.ant-row):first-child,
.batchModal :global(.ant-col) {
  height: 95%;
}

.storetable {
  height: 100%;
}

.storetable :global(.activeBgc) {
  background-color: #6bc2fa;
}

.listTable {
  height: 85%;
}

.listTable :global(.ant-table-thead .ant-checkbox) {
  display: none !important;
}
.query {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  position: relative;
}

.queryItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  padding: 10px 10px 10px 10px;
  background-color: table-header-bg-color;
}

.AddCurePlanModal :global(.ant-modal-body) {
  height: 600px;
  padding: 15px;
}

.templateModal :global(.ant-modal-body) {
  height: 500px;
  padding: 15px;
}

.AddCurePlanModalTable {
  height: 300px;
  flex: 1;
  overflow-y: auto;
}

.AddCurePlanModalTable :global(.ant-dropdown-placement-bottomLeft) {
  top: -40px !important;
  left: 150px !important;
}

.curePlanModalTabel {
  max-height: 300px;
  flex: 1;
  overflow-y: auto;
}
.templateContainer {
  height: 100%;
  overflow: auto;
}
.templateItem {
  padding: 5px;
  border-bottom: 1px solid #e1e1e1;
  cursor: pointer;
}
.templateItem:hover {
  background-color: #bedcfd;
}

.split {
  width: 280px !important;
}

.split :global(.ant-modal-body) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.split :global(.ant-modal-body) button {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0 16px !important;
  line-height: 3rem;
  font-size: 1.125rem;
  text-align: left;
}

.container {
  width: 100%;
  height: calc(100% - 90px);
  overflow-y: auto;
}

.followUpContainer {
  width: 100%;
  height: calc(100% - 90px);
  overflow-y: auto;
}

.tips {
  text-align: center;
  color: #999;
  font-weight: 700;
  font-size: 20px;
  margin-top: 30px;
}

.outRecipeFooter {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.uploadImg {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.outRecipeModal :global(.ant-spin-nested-loading),
.outRecipeModal :global(.ant-spin-container) {
  height: 100% !important;
  width: 100% !important;
}

.supplierIdEye {
  position: absolute;
  top: 0;
  left: 538px;
  font-size: 18px;
}

.disabledMain :global(.ant-select-selection-item) {
  color: #333333;
}

.disabledMain :global(.ant-input-disabled) {
  color: #333333 !important;
}

.disabledMain :global(.ant-radio-disabled + span) {
  color: #333333 !important;
  cursor: not-allowed !important;
}


.persionalSetting :global(.ant-form-item-no-colon) {
  width: 150px !important;
  padding-left: 20px !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.persionalSetting :global(.ant-steps-item-active .ant-steps-item-title) {
  color: #027aff !important;
}

.mainContent {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  justify-content: center;
}

.mainTopContent {
  width: 100%;
  height: 506px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}

.bottonButton {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.addBackContent {
  width: 120px;
  margin-top: 4px;
  margin-top: 10px;
}

.cancelBackContent {
  width: 120px;
  margin-top: 4px;
  margin-top: 10px;
}

.iconBackContent {
  width: 40px;
  height: 44px;
  border: 1px solid rgba(187, 187, 187, 1);
  border-radius: 0 4px 4px 0;
  margin-top: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.backContent {
  width: 168px;
  height: 44px;
  border: 1px solid rgba(187, 187, 187, 1);
  border-right: none;
  border-radius: 4px 0 0 4px;
  margin-top: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.selectBackContent {
  width: 168px;
  height: 44px;
  background-color: #327aff;
  border-radius: 4px 0 0 4px;
  margin-top: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.persionalSetting :global(.ant-form-item-explain-error){
  margin-left: 20px !important;
}

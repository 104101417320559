.patientLable {
    height: calc(100% - 40px);
    margin-top: 20px;
    width: 100%;
    margin-left: -2px;
}

.content {
    height: calc(100% - 70px);
    margin: 10px 0;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
}

.patientLable :global(.ant-table-cell) {
    line-height: 39px;
    padding: 0
}

.autoLabel :global(.ant-modal-body) {
    padding: 10px 20px 10px 40px !important;
}
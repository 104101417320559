.main {
  /* padding: 10px 0; */
  overflow: hidden;
  height: 100%;
}

.panelHeader {
  padding: 10px;
  background-color: #f1f6fe;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.actionItems{
  max-width:60px ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.headerItem {
  display: flex;
  flex-direction: column;
  color: #666;
}

.headerItem :global(.ant-rate) {
  font-size: 12px !important;
}

.headerItem :global(.ant-rate-star) {
  margin-right: 4px !important;
}

.patientInfoTabs {
  height: calc(100% - 80px) !important;
  overflow: auto;
}
.patientInfoTabs :global(.ant-tabs-content) {
  height: 100%;
}
.patientInfoTabs :global(.ant-tabs-content-holder) {
  overflow-y: auto;
}

.patientInfoContainer :global(.ant-row) {
  margin: 0 !important;
}

.patientInfoContainer {
  padding-bottom: 40px;
}

.patientInfoAction {
  background-color: #fff;
  position: absolute;
  bottom: 18px;
  left: 30px;
}

.outpatientInfoContainer :global(.ant-form-item) {
  margin-bottom: 10px;
}
.outpatientInfoContainer :global(.ant-row) {
  margin: 0 !important;
}

.back {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  height: 100%;
  padding-right: 20px;
}

.CurePlanListBtn {
  position: absolute !important;
  left: 10px;
  bottom: 0;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  overflow-y: hidden;
}
/* 设置样式 */
.set {
  position: absolute;
  right: 50px;
  line-height: 40px;
  cursor: pointer;
}



/* 周时间容器样式 */
.weekTimeWrap {
  margin: 0 10px;
  width: 200px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #ccc;
}

/* 重置排班模态框标题样式 */
.modalIcon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background-color: red;
  border: 1px solid red;
  border-radius: 50%;
}
.setText {
  font-size: 12px;
  color: #2081ff;
  opacity: 0;
  cursor: pointer;
}
/* 表格单元格hover文字 */
.editText {
  position: absolute;
  top: 0;
  width: 50%;
  opacity: 0;
}
/* 表格单元格数据 */

.tableCellText {
  opacity: 1;
  width:50%
  /* background-color: red; */
}

.tableCellTexts{
  opacity: 1;
  width:50%
}

/* 过期日期 */
.tableCellTextForbid,
.tableCellTextForbidColor {
  height: 100%;
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.tableCellTextForbids{
  height: 100%;
  width:50%;
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.tableCellTextForbidColor {
  color: #666666;
  width:50%;
  background-color: #f0f0f0;
}



.tableGroup :global(.ant-table-row .ant-table-cell):hover .setText {
  opacity: 1;
  width: 100%;
}
.tableGroup :global(.ant-table-row .ant-table-cell):hover .editText {
  opacity: 1;
  width: 100%;
}
.tableGroup :global(.ant-table-row .ant-table-cell):hover .tableCellText {
  opacity: 0;
}
.tableGroup :global(.ant-table-cell) {
  line-height: 2.5rem;
  padding: 0 !important;
}

/* 表格单元格内字体样式 */
.tableCellWrapText {
  width: 100%;
  font-size: 12px;
  color: #2081ff;
  cursor: pointer;
}

.tableCellIcon {
  position: absolute;
  top: -10px;
  right: 0;
  cursor: pointer;
}

/* 新增/编辑班次弹窗样式 */
.raidoWrap {
  position: absolute;
  padding: 20px 20px 50px;
  width: 270px;
  height: 300px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0px 0px 7px 0px rgba(000, 000, 000, 0.2);
  z-index: 999;
}
.main {
  height: 98.4%;
  margin-top: 10px;
  padding: 0 20px;
  background: #ffffff;
  border: none;
  display: flex;
  flex-direction: column;
  border-radius: 10px !important;
  overflow: auto;
}

.titletext {
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #f3f7fd;
  width: 100%;
  color: #333333;
}

.form {
  width: 1240px !important;
}

.table {
  margin-top: 20px;
  height: 100%;
  overflow: auto;
}

.footer {
  margin: 12px 20px;
  height: calc(100% - 70% - 81px);
  margin: 12px 20px;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
}

.lable:global(.ant-col .ant-form-item .ant-form-item-label > label) {
  font-size: 18px;
}

.Qingdou {
  height: calc(100% - 40px);
  width: 100%;
  margin-left: -2px;
  /* margin-top: -30px; */
}

.queryA {
  margin: 0 0 10px 0;
  display: flex;
}

.queryA > a {
  margin-right: 10px;
  line-height: 32px;
  text-align: center;
  display: inline-block;
  width: 72px;
  height: 32px;
  border-radius: 2px;
}

.container {
    padding: 10px 22px 0 30px;
}

.bar {
    height: 2.375rem;
    background-color: #ffffff;
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 20px 0 0;
    margin-bottom: 12px;
}

.main {
    max-width: 100%;
    height: calc(100% - 10px);
    background: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    overflow: hidden;
    margin: 15px 0;
    padding: 10px 20px;
}
.mains {
    max-width: 100%;
    height: calc(100% - 80px);
    background: #ffffff;
    border-radius: 10px;
    margin: 0 auto;
    overflow: hidden;
    margin: 15px 20px;
    padding: 10px 22px 0 30px;
}


.buttom {
    /* margin:22% 27px 30px 80%; */
}

.buttom_submit {
    margin-top: 20px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.message {
    margin-bottom: 0 !important
}
.content {
    height: calc(100% - 10px);
    margin-top: 10px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
}

.pitest {
    flex: 1;
    border-radius: 10px !important;
}
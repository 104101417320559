.PurchaseAcceptanceList {
  height: calc(100% - 5px);
  overflow-y: scroll;
  border-radius: 10px;
  margin: 10px 0;
 
}

.content {
  height: calc(100% - 70px);
  margin: 10px 20px;
  padding: 0px 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}


.table {
  /* height: calc(100% - 100px);
  overflow: scroll;
  font-size: 14px; */
}

.saveform {
  height: 93%;
  padding: 20px;
  padding-bottom: 40px;
  margin: 10px 20px;
  border-radius: 10px;
  background-color: #ffffff;
  overflow-y: scroll;
}

.title {
  height: 20px;
  background: #ffffff;
  box-shadow: 0px 1px 0px 0px #f3f7fd;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  margin-bottom: 20px;
}

.titletext {
  padding-left: 10px;
  border-left: 2px #0052d9 solid;
  height: 12px;
  font-size: 20px;
  line-height: 10px;
  font-weight: bold;
  color: #333333;
}

.saveform :global(.ant-input-disabled) {
  color: #333 !important;
}

.saveform :global(.ant-picker-input > input) {
  color: #333 !important;
}

.saveform
  :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector) {
  color: #333 !important;
}


.input {
  width: 315px !important;
  margin-right: 10px;
}

.select {
  width: 180px !important;
  margin-right: 10px !important;
 
}

.space {
  flex: 1;
}

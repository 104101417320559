.buttonStyle :global(.ant-btn) {
  padding: 0 !important;
}

.table :global(.ant-table-cell-fix-right-first):nth-of-type(9){
   background-color: #d9f2ff !important;
}

/* 收费信息合计行样式 */
.table :global(.ant-table-cell-fix-right-first):nth-of-type(10) {
  background-color: #d9f2ff !important;
}

.total {
  background-color: #d9f2ff !important;
}

.table {
  margin-top: 10px;
  height: calc(100% - 160px);
  padding-left: 10px;
}

.table :global(.ant-spin-container) {
  /* min-width: 1366px !important; */
 height: 80% !important;
}

.table :global(.ant-spin-nested-loading) {
 height:100% !important;
}

.table :global(.ant-spin-container) {
 height:100% !important;
}

.table :global(.ant-table) {
 height:100% !important;
}

.table :global(.ant-table-container) {
 height:100% !important;
}

.table :global(.ant-table-content) {
 height:100% !important;
}




.main {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
  /* margin-top: 10px; */
  overflow: hidden;
  padding-top: 10px;
}

.query {
  display: flex;
  /* justify-content: space-between; */
  /* align-items: flex-start; */
  /* height: 45px; */
  margin-bottom: 10px;
}

.table :global(.ant-btn-sm) {
  padding: 0 !important;
}
.table :global(.ant-spin-nested-loading),
.table :global(.ant-spin-container),
.table :global(.ant-table),
.table :global(.ant-table-container),
.table :global(.ant-table-content) {
  height: 100%;
}

.table :global(.ant-table-body) {
  /* height:calc(100% -40px) !important; */
  overflow: auto !important;
  max-height: 570px !important;

}

.table :global(.ant-table-content) {
  overflow: auto !important;
}

.table :global(.ant-table-wrapper) {
  overflow: hidden !important;
}



.iconlabel {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  margin-left: 5px;
  /* margin-bottom: 5px; */
  cursor: default;
  border-radius: 3px;
}

.icon {
  display: flex;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  /* margin-bottom: 5px; */
  cursor: default;
}

.cellLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.star :global(.ant-rate) {
  font-size: 10px !important;
}

.star :global(.ant-rate-star) {
  margin-right: 1px !important;
}

.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
  text-align: left;
}

.labelName {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.cellLeft :global(.ant-space-item) {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .cloumnGroup {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */

@value colors: "../../colors.css";
@value primary-color, text-color-1, text-color-2, text-color-3, input-border-color from colors;

.container {
  padding: 10px 22px 0 30px;
}

.bar {
  height: 2.375rem;
  background-color: #ffffff;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
  margin-bottom: 12px;
}

.main {
  max-width: 100%;
  height: calc(100% - 30px);
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  overflow: hidden;
  margin: 15px;
  padding: 10px 20px;
}

.buttom {
  /* margin:22% 27px 30px 80%; */
}

.buttom_submit {
  margin-top: 20px;
  width: 70px;
  height: 30px;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.formItem{
  position: relative;

}

.span{
  width: 200px;
  position: absolute;
  top: 8px;
  right: -210px;
}
.imgWrap {
  width: 310px;
  height: 250px;
background-image: url(./setting/img/JEML.png);
}
.text {
  font-style: oblique;
  color: #949595;
}

.select {
  width: 450px !important;
  min-width: 450px;
}
.stockfloor {
    height: calc(100% - 40px);
    width: 100%;
    margin-left: -2px;
    margin-top: -10px;
}

.stockfooltable {
    width: 100%;
    height: 100%;
    overflow: scroll;
}

.content {
    height: calc(100% - 70px);
    margin: 10px 20px;
    padding: 20px;
    background: #FFFFFF;
    border-radius: 10px !important;
    display: flex;
    flex-direction: column;
}
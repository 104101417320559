.tabs {
  margin: 10px 20px !important;
}

.title{
  height: 40px;
  display: flex;
  align-items: center;
}

.main{
  margin-bottom: 10px;
}

.text{
  color: #666666 ;
}
.wrap {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 15px 15px 0;
  height: calc(100% - 30px);
  border-radius: 6px;
  background-color: #fff;
}

.tableText {
  color: #0e80ff;
  cursor: pointer;
}

.formWrap :global(.ant-form-item) {
  margin-bottom: 10px;
}
.iptText {
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  width: 40px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  background-color: #a4adb3;
}

/* 会员管理详情 */
.detailWrap {
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  overflow: hidden;
  margin: 10px 20px;
}
.contentWrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 10px;
  padding-left: 50px;
  background-color: #fff;
  border-radius: 6px;
  overflow: hidden;
}
.contentTitle {
  margin-bottom: 20px;
  height: 60px;
  line-height: 60px;
  /* border-bottom: 1px solid #ccc; */
}
.patientLable {
  height: calc(100% - 70px);
  margin-top: 20px;
  width: 100%;
  margin-left: -2px;
}

/* 会员搜索 */
.infoWrap {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.infoIcon {
  margin-top: 3px;
  font-style: 20px;
  color: #f98e35;
}
.infoText {
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
}
.footerBtn {
  display: flex;
  justify-content: end;
  margin-top: 50px;
}

.iconStyle {
  font-size: 14px !important;
  /* width: 24px;
  height: 24px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 3px !important;
  margin: 0 !important;
  border-radius: 3px !important;
  border: none !important;
}

.total {
  background-color: #d9f2ff !important;
}

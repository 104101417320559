.durg {
    width: 100%;
    height: 100%;
}

.head {
    background-color: #ffffff;
    margin-left: 19px;
    margin-right: 21px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 3px;
    padding-bottom: 5px;
    position: relative !important;
    height: 38px;
    box-shadow: 0px 2px 0px 0px #027aff;
}

.add {
    position: absolute !important;
    right: 20px !important;
}

.content {
    padding: 10px 20px 28px 20px;
    margin: 12px 20px;
    background: #fff;
    position: relative;
}

.form {
    width: 560px !important;
}

.permissionform {
    width: 80% !important;
    height: 80% !important;
}

.queryA {
    margin: 0 0 10px 0;
}

.queryA>a {
    margin-right: 10px;
    line-height: 32px;
    text-align: center;
    display: inline-block;
    width: 72px;
    height: 32px;
    border-radius: 2px;
}

.durgsystemtable th {
    padding: 0 10px !important;
    height: 2.5rem;
    line-height: 0.3rem;
}
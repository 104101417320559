.form :global(.ant-form-item) {
  padding: 0 !important;
}
.form :global(.ant-picker) {
  width: 271px !important;
}
.moveBtn {
  position: absolute;
  right: -20px;
  top: 10px;
  z-index: 99;
}
.addModal :global(.ant-modal-body){
  padding: 0 24px !important
}
.content {
  height: calc(100% - 20px);
  margin: 10px 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.table {
  height: calc(100% - 90px);
  overflow: auto;
}

.table td {
  white-space: nowrap;
}

.table tr th {
  padding: 0 10px !important;
  height: 2.5rem;
  white-space: nowrap;
}

.form {
  height: calc(100% - 80px);
  margin: 10px 20px;
  padding: 20px;
  overflow: auto;
  border-radius: 10px !important;
  position: relative;
  background: #fff;
}

.riskArea {
  height: calc(100% - 80px);
  width: 100%;
  background: #f0f0f0;
  padding: 0 20px;
  overflow-y: auto;
}

.riskheader {
  width: 100%;
  height: 250px;
  background: #fff;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.riskArea p {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
  margin: 0;
}

.riskheader > div {
  padding: 16px;
  text-align: center;
}

.riskcard {
  flex: 1;
  margin: 20px 0;
}

.riskcard + .riskcard {
  border-left: 1px solid #e0e0e0;
}

.riskItem {
  background: #fff;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 10px;
}

.riskItem > p + .riskItem > p {
  border-top: 1px solid #e0e0e0;
  padding: 10px 0;
  margin: 0;
}

.riskItem p:last-child {
  display: flex;
  justify-content: space-between;
}

.area + .area {
  margin-left: 16px;
}

.form :global(.ant-form) {
  width: 100% !important;
}

.table :global(.ant-spin-container) {
  min-width: 1366px !important;
}

#editor {
  height: calc(100% - 80px);
  width: 100%;
  overflow: hidden;
}

.sde-toolbars {
  width: 100%;
  height: auto;
}

.sde-editor {
  height: calc(100% - 10px);
  background-color: #fff;
}

.tab-list {
  background-color: #fff;
  border-bottom-color: #fff;
}

.tab-list-item {
  background-color: #fff;
}

.tab-list-item-active {
  background-color: #bedcfd !important;
}

.tab-list-item:hover {
  background-color: #bedcfd !important;
}

.tab-list {
  display: none;
}

@value colors: "../../../colors.css";
@value text-color-2 from colors;
.table td {
  color: text-color-2 !important;
  padding: 0 4px !important;
  font-size: 1rem;
  height: 3rem;
  line-height: 3rem;
}

.serviceDelay {
  height: calc(100% - 70px);
  margin: 10px 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.title:hover {
  overflow: visible !important;
  transition: all 0.4s;
  height: 48px;
  box-shadow: 1px 1px 1px 1px #f3f7fd;
}

.title:hover > i {
  width: auto;
  display: inline;
  background-color: #ffffff;
  padding: 13px 11px 13px 11px;
  border: 1px solid rgb(238, 238, 238);
  z-index: 9999999;
  position: relative;
}

/* .title:hover+.titles {
    opacity: 0;
} */

.point:hover {
  cursor: pointer;
}

.total {
  background-color: #d9f2ff;
}

.footingTitle {
  text-align: center;
}

.detail {
  margin-bottom: 15px;
  font-size: 1rem;
}

.select {
  width: 220px !important;
  min-width: 220px;
}

.selects {
  width: 150px !important;
  min-width: 150px;
}

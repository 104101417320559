.main {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.table :global(.ant-spin-nested-loading),
.table :global(.ant-spin-container),
.table :global(.ant-table),
.table :global(.ant-table-container),
.table :global(.ant-table-content){
  height:100%
}

.table :global(.ant-table-content){
  overflow: auto !important;
}

.add{
  position: absolute !important;
  left: 10px;
  bottom: 0px;
}


/* .addOutside :global(.ant-form-item-label){
  display: flex !important;
  align-items: center;
} */

.addOutside :global(.ant-dropdown-placement-bottomLeft){
  left: 145px !important;
  top: 15px !important;
}

.addOutside :global(.ant-picker){
width: 100%;
}


.outsideContainer{
  height: calc(100% - 40px);
  overflow-y: auto;
}

.outsideContainer :global(.ant-row) {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.flex{
  width: 100%;
 white-space: break-spaces;
}

.footer {
  width: 90%;
  overflow: hidden;
  margin-left: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 32px;
}
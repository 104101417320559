@value colors: "../../../../colors.css";
@value primary-color, text-color-1, text-color-2, text-color-3, table-header-bg-color from colors;

.table th {
  background-color: table-header-bg-color;
}

.table th,
.table td {
  font-size: 1rem;
  padding:8px 2px !important
}

.table table {
  width: auto;
}

.table :global(.ant-table-selection-column) {
  display: none;
}

.gray {
  background-color: #f4f4f4;
}

.table :global(.ant-table-tbody > tr.ant-table-row-selected > td){
   background-color:#a4d9fd !important; 
}

.table :global(.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td) {
  border-right: 1px solid #ddd !important;
}

.table :global(.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th) {
  border-right: 1px solid #ddd !important;
}

.table :global(.ant-table-tbody > tr > td) {
  border-bottom: 1px solid #ddd;
}

.table :global(.ant-table-thead > tr > th) {
  border-bottom: 1px solid #ddd;
}

.table :global(.ant-table-bordered > .ant-table-container > .ant-table-content > table) {
  border-top: 1px solid #ddd !important;
}

.table :global(.ant-table-bordered > .ant-table-container) {
  border-left: 1px solid #ddd !important;
}
.name {
  white-space: nowrap;
}

.popverContent :global(.ant-popover-content) {
  position: relative !important;
  top: 80px !important;
}

.remarkStyle {
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

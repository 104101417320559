.main {
  height: 100%;
  padding: 10px 20px;
}

.tabs:global(.ant-tabs) {
  height: 100%;
  /* background-color: #fff !important; */
  margin: 10px 20px;
}

.tabs :global(.ant-tabs-nav) {
  margin: 0 !important;
}

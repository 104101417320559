    .statement {
        flex: 1;
        border-radius: 10px !important;
    }
    
    .content {
        height: calc(100% - 30px);
        margin: 10px 0px;
        padding: 20px;
        background: #FFFFFF;
        border-radius: 10px !important;
        display: flex;
        flex-direction: column;
    }
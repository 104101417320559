.contenter {
  height: calc(100% - 10px);
  margin-top: 10px;
  padding: 20px;
  background: #ffffff;
  border: none;
  display: flex;
  flex-direction: column;
  border-radius: 10px !important;
}

.formula,
.noformula {
  flex: 1;
  border-radius: 10px !important;
}

.head :global(.ant-collapse-header) {
  background: #ffffff;
  height: 48px;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  padding: 0;
}

.header :global(.ant-collapse-header) {
  background: #ffffff;
  height: 40px;
  line-height: 40px;
  background-color: #f4f8fb;
}

.main :global(.ant-collapse-header) {
  background: #ffffff;
  height: 40px;
  line-height: 40px;
  background-color: #f4f8fb;
}

.header :global(.ant-collapse-item) {
  background-color: #ffffff !important;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  box-sizing: border-box;
}

.main :global(.ant-collapse-content-box) {
  background-color: #ffffff !important;
}

.table :global(.ant-collapse-content-box) {
  margin: 10px !important;
  background-color: #f4f8fb;
}

.content :global(.ant-collapse-content-box) {
  padding: 0 !important;
  height: 100%;
}

.table:global(.ant-table-cell) {
  height: 48px;
}

[data-theme='compact']
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}

.space {
  height: 48px;
  width: 972px;
}

.space :global(.ant-space-item) {
  height: 48px;
  padding: 0;
}

.space :global(.ant-collapse-header) {
  height: 48px;
  padding: 0 16px !important;
  display: flex !important;
  align-items: center !important;
}

.content {
  height: calc(100% - 20px);
  margin: 10px 20px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.table {
  height: calc(100% - 90px);
  overflow: auto;
}

.form {
  height: calc(100% - 70px);
  margin: 10px 20px;
  padding: 20px;
  overflow-y: auto;
  border-radius: 10px !important;
  position: relative;
  background: #ffffff;
}

.bar input,
.form input {
  color: text-color-1;
  font-size: 1rem !important;
  height: 2rem !important;
  padding: 2px 6px !important;
}

.bar :global(.ant-input-affix-wrapper),
.form :global(.ant-input-affix-wrapper) {
  padding: 0 11px;
}

.bar input::placeholder {
  font-size: 1rem;
}

.containerItem {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  background-color: #fff;
  overflow-y: hidden;
}

.containerItem :global(.ant-input-disabled) {
  color: #333 !important;
}
.containerItem
  :global(.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector) {
  color: #333 !important;
}


.sellingExpenseSummaryMain {
  flex: 1;
  border-radius: 10px !important;
}

/* 打印样式 */
.sellingExpenseSummaryMains {
  /* flex: 1; */
  height: 300px;
}

.sellingExpenseSummaryMains th {
  border: 1px solid #333 !important;
  border-right: none !important;
}

.sellingExpenseSummaryMains th:last-child {
  border-right: 1px solid #333 !important;
  border-top-right-radius: 10px !important;
}

.sellingExpenseSummaryMains th:first-child {
  border-top-left-radius: 10px !important;
}

.sellingExpenseSummaryMains td {
  border: 1px solid #333 !important;
  border-top: none !important;
  border-right: none !important;
}

.sellingExpenseSummaryMains td:last-child {
  border-right: 1px solid #333 !important;
}

.content {
  height: calc(100% - 30px);
  margin: 10px 0px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.contents{
  height: calc(100% - 30px);
  margin: 10px 0px;
  padding:10px 20px;
  background: #ffffff;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
}

.modal :global(.ant-modal-content) {
  height: 650px !important;
}

.modal :global(.ant-table-content) {
  height: 450px !important;
}

.modal :global(.ant-modal-content .ant-row) {
  height: 50px !important;
}

.total {
  background-color: #d9f2ff;
}

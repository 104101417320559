.setmeal {
    width: 100%;
    background-color: #ffffff;
    margin-top: 11px;
    padding: 14px;
    position: relative;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    overflow-y: hidden;
}

.pagination {
    height: 50px;
    position: relative;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    height: 100%;
    overflow-y: hidden;
}

.create {
    position: absolute !important;
    top: 14px;
    right: 30px;
}

.queryA {
    margin-right: 9px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    height: 32px;
    padding: 0px 20px;
    border-radius: 2px;
    /* margin-top: 24px; */
}

.queryB {
    margin-right: 9px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    height: 32px;
    padding: 0px 20px;
    border-radius: 2px;
    margin-top: 24px;
}
.setmealform {
    width: 560px !important;
}

.setmealDurgfrom {
    width: 560px !important;
}

.detailtable tr th {
    padding: 0 10px !important;
    height: 2.5rem;
    white-space: nowrap;
}

.detailtable td {
    padding: 0 10px !important;
    height: 2.5rem;
    white-space: nowrap;
}

.table {
    flex: 1;
    overflow-y: auto;
}

.table th,
.table td {
    color: text-color-2;
    white-space: nowrap;
    line-height: 2.5rem;
    text-align: center;
    padding: 0 8px !important;
    font-size: 1rem !important;
}

.point {
    display: flex;
    align-items: center;
}

.title:hover {
    overflow: visible !important;
    transition: all .4s;
}

.title:hover>i {
    width: auto;
    display: inline;
    padding: 7px 10px 7px 10px;
    background-color: #ffffff;
    border: 1px solid rgb(238, 238, 238);
    box-shadow: 0px 1px 0px 0px #F3F7FD;
    z-index: 9999999;
    position: relative;
}

.title:hover+.titles {
    opacity: 0;
}

.point:hover {
    cursor: pointer;
}

/* 拆零 */
.split {
  width: 280px !important;
}

.split :global(.ant-modal-body) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.split :global(.ant-modal-body) button {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0 16px !important;
  line-height: 3rem;
  font-size: 1.125rem;
  text-align: left;
}

.hint {
  color: red;
  margin-top: 27px;
}

.queryA {
    margin-right: 9px;
    line-height: 30px;
    text-align: center;
    display: inline-block;
    height: 32px;
    padding: 0px 20px;
    border-radius: 2px;
}
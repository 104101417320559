.container,
.nomargin {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  margin: 10px 20px;
}

.bar {
  margin-bottom: 10px;
}

.panel {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 20px;
  border-radius: 10px;
  background-color: #ffffff;
  overflow-y: hidden;
}

.list {
  flex: 1;
  height: 100%;
  overflow-y: hidden;
}

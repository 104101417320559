.container {
  padding: 10px 20px;
  height: 100%;
  overflow-y: hidden;
}

.tip :global(.ant-input-number-input) {
  text-align: center !important;
}

.tip :global(.ant-btn > .anticon + span) {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 450px !important;
  height: 18px !important;
}

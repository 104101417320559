.container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container > :global(.ant-tabs) {
  display: flex !important;
}

.container :global(.ant-tabs-content-holder) {
  flex: 1 !important;
  /* overflow-y: auto; */
}

.container :global(.ant-tabs-nav) {
  height: 32px !important;
  line-height: 32px !important;
}

.tabs :global(.ant-tabs-tab) {
  height: 32px !important;
  line-height: 32px !important;
}

.container :global(.ant-tabs-tab-btn) {
  font-size: 20px !important;
}

.container :global(.ant-tabs-content) {
  height: 100%;
}

.tabs {
  flex: 1;
  overflow-y: 'hidden';
  margin: 10px 20px 10px 20px !important;
}

.message {
  margin-bottom: 0 !important;
}

.content {
  height: calc(100% - 32px);
  overflow: auto;
}

.label {
  margin-left: 3px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.ModalContent :global(.ant-table-tbody > tr > td) {
  padding: 8px 16px !important;
}

.ModalContent :global(tr.ant-table-measure-row) {
  visibility: collapse !important;
}

.cxybljButton :global(.ant-btn) {
  padding: 4px 5px !important;
}

.backIcon {
  width: 14px !important;
  height: 14px !important;
  margin-left: '2px';
}

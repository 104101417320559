@value colors: "../../colors.css";
@value primary-color, tertiary-color, selected-color, success-color, text-color-1, text-color-2 from colors;

.tabs :global(.ant-tabs-content) {
  height: 100%;
  overflow: hiddenØ;
}

.table th,
.table td {
  font-size: 1rem !important;
  white-space: nowrap;
  padding: 16px 8px !important;

}

.table :global(.ant-table-row-selected) td {
  background-color: transparent !important;
}

/* .table
  :global(.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table
    tfoot
    > tr
    > th, .ant-table tfoot > tr > td) {
} */

.settled > td:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 4px solid success-color;
}

.selected {
  background-color: selected-color;
}

.settled > td {
  color: success-color !important;
}

.selected > td:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 4px solid primary-color;
}

.settledSelected > td:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 4px solid #1cd0bd;
}

.elseSelected {
  border-radius: 4px 0 0 4px;
  border-left: 4px solid primary-color;
}

.returnedSelected > td:first-child {
  border-radius: 4px 0 0 4px;
  border-left: 4px solid #ccc;
}

.selected > td {
  color: primary-color !important;
  font-weight: bold;
}

.history :global(.ant-card) {
  margin: 10px;
}

.history :global(.ant-card-head) {
  min-height: 2rem;
  padding: 0px 20px;
  background-color: tertiary-color;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: solid 3px primary-color;
}

.history :global(.ant-card-head-title) {
  font-size: 1.125rem;
  color: text-color-1;
  line-height: 2rem;
  height: 2rem;
  padding: 0px;
}

.history :global(.ant-card-body) {
  color: text-color-2;
  font-size: 1rem;
  padding: 10px 20px;
}

.contater {
  border-bottom: 1px solid #f1f1f1;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.tip :global(.ant-input-number-input) {
  text-align: center !important;
}

.tip :global(.ant-btn > .anticon + span) {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 450px !important;
  height: 18px !important;
}
